import { App } from 'app';
import { createRoot } from 'react-dom/client';

import './shared/utilities/i18n';
import 'styles/global.scss';
import { Provider } from 'jotai';
import { ErrorBoundary } from './shared/components';

const container = document.getElementById('root');
const root = createRoot(container as Element);
root.render(
  <Provider>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>
);

import { t } from 'i18next';

import {
  aluminiumGutterFormStructure,
  cropWireSystemFormStructure,
  dimensionsOFInnerFieldFormStructure,
  gableEndFormStructure,
  geometryAndConstructionFormStructure,
  groundAndFoundationsPropertiesFormStructure,
  liveLoadsFormStructure,
  loadsServicesAndMaintenanceFormStructure,
  partitionsInsideGreenhouseFormStructure,
  rainwaterSystemFormStructure,
  sideWallFormStructure,
} from '../../data/formStructureGeometry.data';
import { generalGlazenStadFormStructure } from '../../data/formStructureGlazenStad.data';
import {
  artificialLightingFormStructure,
  carbonDioxideFormStructure,
  constructionFormStructure,
  coolingFormStructure,
  cropFormStructure,
  generalSiomFormStructure,
  heatingFormStructure,
  photovoltaicFormStructure,
  screensFormStructure,
  semiClosedSystemFormStructure,
  ventilationFormStructure,
  waterManagementSystemFormStructure,
} from '../../data/formStructureSiom.data';
import { SiomTemplate, VerifyDataType } from '../../types/project.types';
import { KISForm } from './shared';
import { TemplateForm } from './shared/template-form/form';
import { VerifyForm } from './shared/verify-form/form';
import { GlazenStadForm } from './shared/glazenStad-form/form';

export const getGeomertyAndConstructionItems = (
  disabled: boolean
): {
  key: string;
  label: string;
  children: JSX.Element;
}[] => [
  {
    key: 'geometryAndConstruction.geometryGenericDesignData',
    label: t('projects.geometryAndConstruction.geometryGenericDesignData.title'),
    children: <KISForm disableFields={disabled} maxFormHeight="1000px" formStructure={geometryAndConstructionFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.dimensionsOFInnerField',
    label: t('projects.geometryAndConstruction.dimensionsOFInnerField.title'),
    children: <KISForm disableFields={disabled} formStructure={dimensionsOFInnerFieldFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.partitionsInsideGreenhouse',
    label: t('projects.geometryAndConstruction.partitionsInsideGreenhouse.title'),
    children: <KISForm disableFields={disabled} formStructure={partitionsInsideGreenhouseFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.groundAndFoundationsProperties',
    label: t('projects.geometryAndConstruction.groundAndFoundationsProperties.title'),
    children: <KISForm disableFields={disabled} formStructure={groundAndFoundationsPropertiesFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.liveLoads',
    label: t('projects.geometryAndConstruction.liveLoads.title'),
    children: <KISForm disableFields={disabled} formStructure={liveLoadsFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.cropWireSystem',
    label: t('projects.geometryAndConstruction.cropWireSystem.title'),
    children: <KISForm disableFields={disabled} formStructure={cropWireSystemFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.loadsServicesAndMaintenance',
    label: t('projects.geometryAndConstruction.loadsServicesAndMaintenance.title'),
    children: <KISForm disableFields={disabled} formStructure={loadsServicesAndMaintenanceFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.gableEnd',
    label: t('projects.geometryAndConstruction.gableEnd.title'),
    children: <KISForm disableFields={disabled} maxFormHeight="830px" formStructure={gableEndFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.sideWall',
    label: t('projects.geometryAndConstruction.sideWall.title'),
    children: <KISForm disableFields={disabled} maxFormHeight="820px" formStructure={sideWallFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.aluminiumGutter',
    label: t('projects.geometryAndConstruction.aluminiumGutter.title'),
    children: <KISForm disableFields={disabled} formStructure={aluminiumGutterFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.rainwaterSystem',
    label: t('projects.geometryAndConstruction.rainwaterSystem.title'),
    children: <KISForm disableFields={disabled} formStructure={rainwaterSystemFormStructure} />,
  },
];

export const getVerifyItems = (
  verifyData: VerifyDataType
): {
  key: string;
  label: string;
  children: JSX.Element;
}[] => [
  {
    key: 'geometryAndConstruction.geometryGenericDesignData',
    label: t('projects.geometryAndConstruction.geometryGenericDesignData.title'),
    children: <VerifyForm verifyData={verifyData} formStructure={geometryAndConstructionFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.dimensionsOFInnerField',
    label: t('projects.geometryAndConstruction.dimensionsOFInnerField.title'),
    children: <VerifyForm verifyData={verifyData} formStructure={dimensionsOFInnerFieldFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.partitionsInsideGreenhouse',
    label: t('projects.geometryAndConstruction.partitionsInsideGreenhouse.title'),
    children: <VerifyForm verifyData={verifyData} formStructure={partitionsInsideGreenhouseFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.groundAndFoundationsProperties',
    label: t('projects.geometryAndConstruction.groundAndFoundationsProperties.title'),
    children: <VerifyForm verifyData={verifyData} formStructure={groundAndFoundationsPropertiesFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.liveLoads',
    label: t('projects.geometryAndConstruction.liveLoads.title'),
    children: <VerifyForm verifyData={verifyData} formStructure={liveLoadsFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.cropWireSystem',
    label: t('projects.geometryAndConstruction.cropWireSystem.title'),
    children: <VerifyForm verifyData={verifyData} formStructure={cropWireSystemFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.loadsServicesAndMaintenance',
    label: t('projects.geometryAndConstruction.loadsServicesAndMaintenance.title'),
    children: <VerifyForm verifyData={verifyData} formStructure={loadsServicesAndMaintenanceFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.gableEnd',
    label: t('projects.geometryAndConstruction.gableEnd.title'),
    children: <VerifyForm verifyData={verifyData} formStructure={gableEndFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.sideWall',
    label: t('projects.geometryAndConstruction.sideWall.title'),
    children: <VerifyForm verifyData={verifyData} formStructure={sideWallFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.aluminiumGutter',
    label: t('projects.geometryAndConstruction.aluminiumGutter.title'),
    children: <VerifyForm verifyData={verifyData} formStructure={aluminiumGutterFormStructure} />,
  },
  {
    key: 'geometryAndConstruction.rainwaterSystem',
    label: t('projects.geometryAndConstruction.rainwaterSystem.title'),
    children: <VerifyForm verifyData={verifyData} formStructure={rainwaterSystemFormStructure} />,
  },
];

export const getSiomItems = (
  disabled: boolean,
  fetchProject: () => void,
  templates?: SiomTemplate[]
): {
  key: string;
  label: string;
  children: JSX.Element;
}[] => [
  {
    key: 'siom.generalSiom',
    label: t('projects.siom.generalSiom.title'),
    children: <KISForm disableFields={disabled} maxFormHeight="1000px" formStructure={generalSiomFormStructure} />,
  },
  {
    key: 'siom.template',
    label: t('projects.siom.template.title'),
    children: <TemplateForm disableFields={disabled} templates={templates} fetchProject={fetchProject} />,
  },
  {
    key: 'siom.construction',
    label: t('projects.siom.construction.title'),
    children: <KISForm disableFields={disabled} formStructure={constructionFormStructure} />,
  },
  {
    key: 'siom.semiClosedSystem',
    label: t('projects.siom.semiClosedSystem.title'),
    children: <KISForm disableFields={disabled} formStructure={semiClosedSystemFormStructure} />,
  },
  {
    key: 'siom.crop',
    label: t('projects.siom.crop.title'),
    children: <KISForm disableFields={disabled} formStructure={cropFormStructure} />,
  },
  {
    key: 'siom.heating',
    label: t('projects.siom.heating.title'),
    children: <KISForm disableFields={disabled} formStructure={heatingFormStructure} />,
  },
  {
    key: 'siom.ventilation',
    label: t('projects.siom.ventilation.title'),
    children: <KISForm disableFields={disabled} formStructure={ventilationFormStructure} />,
  },
  {
    key: 'siom.screens',
    label: t('projects.siom.screens.title'),
    children: <KISForm disableFields={disabled} formStructure={screensFormStructure} />,
  },
  {
    key: 'siom.carbonDioxide',
    label: t('projects.siom.carbonDioxide.title'),
    children: <KISForm disableFields={disabled} formStructure={carbonDioxideFormStructure} />,
  },
  {
    key: 'siom.cooling',
    label: t('projects.siom.cooling.title'),
    children: <KISForm disableFields={disabled} formStructure={coolingFormStructure} />,
  },
  {
    key: 'siom.artificialLighting',
    label: t('projects.siom.artificialLighting.title'),
    children: <KISForm disableFields={disabled} formStructure={artificialLightingFormStructure} />,
  },
  {
    key: 'siom.waterManagementSystem',
    label: t('projects.siom.waterManagementSystem.title'),
    children: <KISForm disableFields={disabled} formStructure={waterManagementSystemFormStructure} />,
  },
  {
    key: 'siom.photovoltaics',
    label: t('projects.siom.photovoltaics.title'),
    children: <KISForm disableFields={disabled} formStructure={photovoltaicFormStructure} />,
  },
];

export const getGlazenStadItems = (
  disabled: boolean
): {
  key: string;
  label: string;
  children: JSX.Element;
}[] => [
  {
    key: 'glazenStad.generalGlazenStad',
    label: t('projects.glazenStad.generalGlazenStad.title'),
    children: <KISForm disableFields={disabled} maxFormHeight="1000px" formStructure={generalGlazenStadFormStructure} />,
  },
  {
    key: 'glazenStad.castaData',
    label: t('projects.glazenStad.castaData.title'),
    children: <GlazenStadForm disableFields={disabled} />,
  },
];

import { faHotel, faHouse, faLocationArrow } from '@fortawesome/pro-solid-svg-icons';
import { FormType, MeasurementEnum } from '../types/project.types';

export const generalFormStructure: FormType = {
  sections: [
    {
      title: 'projects.generalInformation.project.title',
      objectName: 'generalInformation',
      icon: faHouse,
      span: 24,
      fields: [
        {
          label: '',
          name: ['project', 'generalInformation', 'id'],
          type: 'text',
          required: true,
          disabled: false,
          hidden: true,
        },

        {
          label: '',
          name: ['project', 'generalInformation', 'projectId'],
          type: 'text',
          required: true,
          disabled: false,
          hidden: true,
        },
        {
          label: 'projects.generalInformation.project.projectName.title',
          tooltip: 'projects.generalInformation.project.projectName.tooltip',
          name: ['project', 'generalInformation', 'projectName'],
          type: 'text',
          required: true,
          disabled: false,
          requiredMessage: 'projects.generalInformation.project.projectName.requiredMessage',
        },
        {
          label: 'projects.generalInformation.project.contractor.title',
          tooltip: 'projects.generalInformation.project.contractor.tooltip',
          name: ['project', 'contractor', 'name'],
          type: 'text',
          required: false,
          disabled: true,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
        {
          label: 'projects.generalInformation.project.client.title',
          tooltip: 'projects.generalInformation.project.client.tooltip',
          name: ['project', 'generalInformation', 'client'],
          type: 'text',
          required: true,
          disabled: false,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
        {
          label: 'projects.generalInformation.project.constructionStartDate.title',
          tooltip: 'projects.generalInformation.project.constructionStartDate.tooltip',
          name: ['project', 'generalInformation', 'constructionStartDate'],
          type: 'date',
          required: true,
          disabled: false,
          requiredMessage: 'projects.generalInformation.project.constructionStartDate.requiredMessage',
        },
        {
          label: 'projects.generalInformation.project.internalId.title',
          tooltip: 'projects.generalInformation.project.internalId.tooltip',
          name: ['project', 'generalInformation', 'internalProjectCode'],
          type: 'text',
          required: true,
          disabled: false,
        },
        {
          label: 'projects.generalInformation.project.status.title',
          tooltip: 'projects.generalInformation.project.status.tooltip',
          name: ['project', 'generalInformation', 'status'],
          type: 'select',
          required: true,
          disabled: false,
          selectOptions: [
            {
              label: 'Concept',
              value: 0,
              key: 0,
            },
            {
              label: 'Offer',
              value: 1,
              key: 1,
            },
            {
              label: 'Processed',
              value: 2,
              key: 2,
            },
          ],
        },
      ],
    },
    {
      title: 'projects.generalInformation.greenhouse.title',
      objectName: 'generalInformation',
      icon: faHotel,
      span: 24,
      fields: [
        {
          label: 'projects.generalInformation.greenhouse.orientation.title',
          tooltip: 'projects.generalInformation.greenhouse.orientation.tooltip',
          name: ['project', 'generalInformation', 'orientation'],
          type: 'number',
          addonAfter: MeasurementEnum.DEGREE,
          required: true,
          disabled: false,
          requiredMessage: 'projects.generalInformation.greenhouse.orientation.requiredMessage',
          min: -90,
          max: 90,
          step: 0.001,
        },
        {
          label: 'projects.generalInformation.greenhouse.greenhouseType.title',
          tooltip: 'projects.generalInformation.greenhouse.greenhouseType.tooltip',
          name: ['project', 'generalInformation', 'type'],
          type: 'select',
          required: true,
          disabled: false,
          requiredMessage: 'projects.generalInformation.greenhouse.greenhouseType.requiredMessage',
          defaultValue: 'Venlo',
          selectOptions: [
            {
              label: 'Venlo',
              value: 'Venlo',
              key: 'Venlo',
            },
          ],
        },
        {
          label: 'projects.generalInformation.greenhouse.remarks.title',
          tooltip: 'projects.generalInformation.greenhouse.remarks.tooltip',
          name: ['project', 'generalInformation', 'remarks'],
          type: 'textarea',
          required: false,
          disabled: false,
        },
      ],
    },
    {
      title: 'projects.generalInformation.location.title',
      objectName: 'generalInformation',
      icon: faLocationArrow,
      span: 24,
      fields: [
        {
          label: 'projects.generalInformation.location.location.title',
          tooltip: 'projects.generalInformation.location.location.tooltip',
          name: ['project', 'generalInformation', 'address'],
          type: 'text',
          required: true,
          disabled: false,
          requiredMessage: 'projects.generalInformation.location.location.requiredMessage',
        },
        {
          label: 'projects.generalInformation.location.locationPlace.title',
          tooltip: 'projects.generalInformation.location.locationPlace.tooltip',
          name: ['project', 'generalInformation', 'city'],
          type: 'text',
          required: true,
          disabled: false,
          requiredMessage: 'projects.generalInformation.location.locationPlace.requiredMessage',
        },
        {
          label: 'projects.generalInformation.location.country.title',
          tooltip: 'projects.generalInformation.location.country.tooltip',
          name: ['project', 'generalInformation', 'country'],
          type: 'select-country',
          defaultValue: 'Netherlands',
          required: true,
          disabled: false,
          requiredMessage: 'projects.generalInformation.location.country.requiredMessage',
        },
        {
          label: 'projects.generalInformation.location.latitude.title',
          tooltip: 'projects.generalInformation.location.latitude.tooltip',
          name: ['project', 'generalInformation', 'latitude'],
          type: 'number',
          min: -90,
          max: 90,
          step: 0.00000001,
          addonAfter: MeasurementEnum.DEGREEN,
          dotSeparator: '.',
          required: true,
          disabled: false,
          requiredMessage: 'projects.generalInformation.location.latitude.requiredMessage',
          defaultValue: 52.0842635,
        },
        {
          label: 'projects.generalInformation.location.longitude.title',
          tooltip: 'projects.generalInformation.location.longitude.tooltip',
          name: ['project', 'generalInformation', 'longitude'],
          type: 'number',
          min: -180,
          max: 180,
          step: 0.00000001,
          addonAfter: MeasurementEnum.DEGREEE,
          dotSeparator: '.',
          required: true,
          disabled: false,
          requiredMessage: 'projects.generalInformation.location.longitude.requiredMessage',
          defaultValue: 5.0824915,
        },
        {
          label: 'projects.generalInformation.location.altitude.title',
          tooltip: 'projects.generalInformation.location.altitude.tooltip',
          name: ['project', 'generalInformation', 'altitude'],
          type: 'number',
          min: -500,
          max: 9000,
          step: 1,
          addonAfter: MeasurementEnum.M,
          dotSeparator: '.',
          required: true,
          disabled: false,
          requiredMessage: 'projects.generalInformation.location.longitude.requiredMessage',
        },
      ],
    },
  ],
};

export const geometryAndConstructionFormStructure: FormType = {
  sections: [
    {
      title: 'projects.geometryAndConstruction.geometryGenericDesignData.bay.title',
      objectName: 'geometryGenericDesign',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.bay.bayWidth.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.bay.bayWidth.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'width'],
          type: 'number',
          addonAfter: MeasurementEnum.M,
          required: true,
          disabled: false,
          step: 0.001,
          min: 3.2,
          max: 8,
        },
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.bay.nrBaysPerSpan.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.bay.nrBaysPerSpan.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'baysPerSpan'],
          type: 'number',
          required: true,
          step: 1,
          disabled: false,
          min: 2,
          max: 6,
        },
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.bay.nrSpans.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.bay.nrSpans.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'numberOfSpans'],
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 1,
          max: 62,
        },
      ],
    },
    {
      title: 'projects.geometryAndConstruction.geometryGenericDesignData.foundation.title',
      objectName: 'geometryGenericDesign',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.foundation.foundationHeightFacade.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.foundation.foundationHeightFacade.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'foundationHeight'],
          addonAfter: MeasurementEnum.MM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 1000,
        },
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.foundation.foundationType.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.foundation.foundationType.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'foundationType'],
          type: 'select',
          required: true,
          disabled: false,
          defaultValue: 'onPiles',
          selectOptions: [
            {
              label: 'On piles',
              value: 'onPiles',
              key: 'onPiles',
            },
            {
              label: 'On pads',
              value: 'onPads',
              key: 'onPads',
            },
            {
              label: 'On floor',
              value: 'onFloor',
              key: 'onFloor',
            },
            {
              label: 'Irrelevant',
              value: 'irrelevant',
              key: 'irrelevant',
            },
          ],
        },
      ],
    },
    {
      title: 'projects.geometryAndConstruction.geometryGenericDesignData.section.title',
      objectName: 'geometryGenericDesign',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.section.sectionLength.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.section.sectionLength.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'sectionLength'],
          type: 'number',
          addonAfter: MeasurementEnum.M,
          required: true,
          disabled: false,
          step: 0.001,
          min: 2,
          max: 8,
        },
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.section.endSectionLength.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.section.endSectionLength.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'endSectionLength'],
          addonAfter: MeasurementEnum.M,
          type: 'number',
          required: true,
          disabled: false,
          step: 0.001,
          min: 2,
          max: 8,
        },
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.section.pathSectionLength.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.section.pathSectionLength.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'pathSectionLength'],
          addonAfter: MeasurementEnum.M,
          type: 'number',
          required: true,
          disabled: false,
          step: 0.001,
          min: 2,
          max: 8,
        },
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.section.gutterLength.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.section.gutterLength.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'gutterLength'],
          addonAfter: MeasurementEnum.M,
          type: 'number',
          required: true,
          disabled: false,
          step: 0.1,
          min: 12,
          max: 350,
        },
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.section.columnHeightFacade.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.section.columnHeightFacade.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'facadeColumnHeight'],
          addonAfter: MeasurementEnum.M,
          type: 'number',
          required: true,
          disabled: false,
          step: 0.001,
          min: 2,
          max: 15,
        },
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.section.trussHeight.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.section.trussHeight.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'trussHeight'],
          addonAfter: MeasurementEnum.MM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 200,
          max: 1200,
        },
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.section.trussLowering.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.section.trussLowering.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'trussLowering'],
          addonAfter: MeasurementEnum.MM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 500,
        },
      ],
    },
    {
      title: 'projects.geometryAndConstruction.geometryGenericDesignData.roof.title',
      objectName: 'geometryGenericDesign',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.roof.roofAngle.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.roof.roofAngle.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'roofAngle'],
          addonAfter: MeasurementEnum.DEGREE,
          type: 'number',
          required: true,
          disabled: false,
          step: 0.1,
          min: 19,
          max: 30,
        },
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.roof.roofGlass.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.roof.roofGlass.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'roofGlass'],
          addonAfter: MeasurementEnum.MM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 200,
          max: 3000,
        },
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.roof.roofGlassEndZone.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.roof.roofGlassEndZone.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'roofGlassEndZone'],
          addonAfter: MeasurementEnum.MM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 200,
          max: 3000,
        },
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.roof.gableEndGlass.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.roof.gableEndGlass.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'gableEndGlass'],
          addonAfter: MeasurementEnum.MM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 200,
          max: 3000,
        },
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.roof.sideWallGlass.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.roof.sideWallGlass.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'sideWallGlass'],
          addonAfter: MeasurementEnum.MM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 200,
          max: 3000,
        },
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.roof.remarks.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.roof.remarks.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'remarks'],
          type: 'textarea',
          required: false,
          disabled: false,
        },
      ],
    },
  ],
};

export const dimensionsOFInnerFieldFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: 'innerField',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.dimensionsOFInnerField.innerField.columnHeight.title',
          tooltip: 'projects.geometryAndConstruction.dimensionsOFInnerField.innerField.columnHeight.tooltip',
          name: ['geometryAndConstructionProject', 'innerField', 'columnHeight'],
          addonAfter: MeasurementEnum.M,
          type: 'number',
          required: true,
          disabled: false,
          step: 0.001,
          min: 2,
          max: 15,
        },
        {
          label: 'projects.geometryAndConstruction.dimensionsOFInnerField.innerField.foundationHeight.title',
          tooltip: 'projects.geometryAndConstruction.dimensionsOFInnerField.innerField.foundationHeight.tooltip',
          name: ['geometryAndConstructionProject', 'innerField', 'foundationHeight'],
          addonAfter: MeasurementEnum.MM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 2200,
        },
        {
          label: 'projects.geometryAndConstruction.dimensionsOFInnerField.innerField.foundationPileType.title',
          tooltip: 'projects.geometryAndConstruction.dimensionsOFInnerField.innerField.foundationPileType.tooltip',
          name: ['geometryAndConstructionProject', 'innerField', 'foundationPileType'],
          type: 'select',
          required: true,
          disabled: false,
          defaultValue: 'concrete',
          selectOptions: [
            {
              label: 'Concrete',
              value: 'concrete',
              key: 'concrete',
            },
            {
              label: 'Steel',
              value: 'steel',
              key: 'steel',
            },
          ],
        },
        {
          label: 'projects.geometryAndConstruction.dimensionsOFInnerField.innerField.remarks.title',
          tooltip: 'projects.geometryAndConstruction.dimensionsOFInnerField.innerField.remarks.tooltip',
          name: ['geometryAndConstructionProject', 'innerField', 'remarks'],
          type: 'textarea',
          required: false,
          disabled: false,
        },
      ],
    },
  ],
};

export const partitionsInsideGreenhouseFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: 'partitionsInside',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.partitionsInsideGreenhouse.numberOfBayPartitions.title',
          tooltip: 'projects.geometryAndConstruction.partitionsInsideGreenhouse.numberOfBayPartitions.tooltip',
          name: ['geometryAndConstructionProject', 'partitionsInside', 'bayPartitions'],
          type: 'number',
          required: true,
          disabled: false,
          step: 0.001,
          min: 0,
          max: 1000,
        },
        {
          label: 'projects.geometryAndConstruction.partitionsInsideGreenhouse.lengthPartitionBeneathGutter.title',
          tooltip: 'projects.geometryAndConstruction.partitionsInsideGreenhouse.lengthPartitionBeneathGutter.tooltip',
          name: ['geometryAndConstructionProject', 'partitionsInside', 'partitionLength'],
          addonAfter: MeasurementEnum.M,
          type: 'number',
          required: true,
          disabled: false,
          step: 0.001,
          min: 0,
          max: 1000,
        },
        {
          label: 'projects.geometryAndConstruction.partitionsInsideGreenhouse.remarks.title',
          tooltip: 'projects.geometryAndConstruction.partitionsInsideGreenhouse.remarks.tooltip',
          name: ['geometryAndConstructionProject', 'partitionsInside', 'remarks'],
          type: 'textarea',
          required: false,
          disabled: false,
        },
      ],
    },
  ],
};

export const groundAndFoundationsPropertiesFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: 'groundAndFoundation',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.groundAndFoundationsProperties.soilType.title',
          tooltip: 'projects.geometryAndConstruction.groundAndFoundationsProperties.soilType.tooltip',
          name: ['geometryAndConstructionProject', 'groundAndFoundation', 'soilType'],
          type: 'select',

          required: true,
          disabled: false,
          defaultValue: 'customSoilType',
          selectOptions: [
            { label: `Custom soil type`, value: 'customSoilType', key: 'customSoilType' },
            { label: `Clay soil, pure, moderately heavy`, value: 'claySoilPureModeratelyHeavy', key: 'claySoilPureModeratelyHeavy' },
            { label: `Clay soil, slightly sandy, moderate`, value: 'claySoilSlightlySandyModerate', key: 'claySoilSlightlySandyModerate' },
            { label: `Clay soil, strongly sandy`, value: 'claySoilStronglySandy', key: 'claySoilStronglySandy' },
            { label: `Sandy soil, pure`, value: 'sandySoilPure', key: 'sandySoilPure' },
            { label: `Sandy soil, slightly argillaceous`, value: 'sandySoilSlightlyArgillaceous', key: 'sandySoilSlightlyArgillaceous' },
            { label: `Sandy soil, strongly argillaceous`, value: 'sandySoilStronglyArgillaceous', key: 'sandySoilStronglyArgillaceous' },
            { label: `Loamy soil, slightly sandy, moderat`, value: 'loamySoilSlightlySandyModerat', key: 'loamySoilSlightlySandyModerat' },
            { label: `Loamy soil, strongly sandy`, value: 'loamySoilStronglySandy', key: 'loamySoilStronglySandy' },
            { label: `Gravel, slightly briny`, value: 'gravelSlightlyBriny', key: 'gravelSlightlyBriny' },
            { label: `Gravel, strongly briny`, value: 'gravelStronglyBriny', key: 'gravelStronglyBriny' },
            { label: `Clay soil, pure, soft`, value: 'claySoilPureSoft', key: 'claySoilPureSoft' },
            { label: `Clay soil, slightly sandy, soft`, value: 'claySoilSlightlySandySoft', key: 'claySoilSlightlySandySoft' },
            { label: `Clay soil, organic`, value: 'claySoilOrganic', key: 'claySoilOrganic' },
            { label: `Loamy soil, slightly sandy, soft`, value: 'loamySoilSlightlySandySoft', key: 'loamySoilSlightlySandySoft' },
            { label: `Peaty soil, not prior loaded`, value: 'peatySoilNotPriorLoaded', key: 'peatySoilNotPriorLoaded' },
            {
              label: `Peaty soil, moderately prior loaded`,
              value: 'peatySoilModeratelyPriorLoaded',
              key: 'peatySoilModeratelyPriorLoaded',
            },
          ],
        },
        {
          label: 'projects.geometryAndConstruction.groundAndFoundationsProperties.lowestGroundwaterLevel.title',
          tooltip: 'projects.geometryAndConstruction.groundAndFoundationsProperties.lowestGroundwaterLevel.tooltip',
          name: ['geometryAndConstructionProject', 'groundAndFoundation', 'lowestGroundwaterLevel'],
          addonAfter: MeasurementEnum.M,
          type: 'number',
          required: true,
          disabled: false,
          step: 0.001,
          min: -100,
          max: 0,
        },
        {
          label: 'projects.geometryAndConstruction.groundAndFoundationsProperties.highestGroundwaterLevel.title',
          tooltip: 'projects.geometryAndConstruction.groundAndFoundationsProperties.highestGroundwaterLevel.tooltip',
          name: ['geometryAndConstructionProject', 'groundAndFoundation', 'highestGroundwaterLevel'],
          addonAfter: MeasurementEnum.M,
          type: 'number',
          required: true,
          disabled: false,
          step: 0.001,
          min: -100,
          max: 0,
        },
        {
          label: 'projects.geometryAndConstruction.groundAndFoundationsProperties.conePenetrationTest.title',
          tooltip: 'projects.geometryAndConstruction.groundAndFoundationsProperties.conePenetrationTest.tooltip',
          name: ['geometryAndConstructionProject', 'groundAndFoundation', 'conePenetrationTest'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.groundAndFoundationsProperties.remarks.title',
          tooltip: 'projects.geometryAndConstruction.groundAndFoundationsProperties.remarks.tooltip',
          name: ['geometryAndConstructionProject', 'groundAndFoundation', 'remarks'],
          type: 'textarea',
          required: false,
          disabled: false,
        },
      ],
    },
  ],
};

export const liveLoadsFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: 'liveLoads',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.liveLoads.typeOfRoofSystem.title',
          tooltip: 'projects.geometryAndConstruction.liveLoads.typeOfRoofSystem.tooltip',
          name: ['geometryAndConstructionProject', 'liveLoads', 'roofSystemType'],
          type: 'select',
          required: true,
          disabled: false,
          defaultValue: 'singleLayer4mmSodaLineGlass',
          selectOptions: [
            {
              label: 'Single-layer: 4 mm Soda line glass',
              value: 'singleLayer4mmSodaLineGlass',
              key: 'singleLayer4mmSodaLineGlass',
            },
            {
              label: 'Laminated <safety> glass',
              value: 'laminatedSafetyGlass',
              key: 'laminatedSafetyGlass',
            },
            {
              label: 'Double layer glass with Argon',
              value: 'doubleLayerGlassWithArgon',
              key: 'doubleLayerGlassWithArgon',
            },
            {
              label: 'Multi-layer plastic sheet',
              value: 'multiLayerPlasticSheet',
              key: 'multiLayerPlasticSheet',
            },
          ],
        },
        {
          label: 'projects.geometryAndConstruction.liveLoads.roofDeadLoad.title',
          tooltip: 'projects.geometryAndConstruction.liveLoads.roofDeadLoad.tooltip',
          name: ['geometryAndConstructionProject', 'liveLoads', 'roofDeadLoad'],
          addonAfter: MeasurementEnum.NM2,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 2000,
        },
        {
          label: 'projects.geometryAndConstruction.liveLoads.gutterWeight.title',
          tooltip: 'projects.geometryAndConstruction.liveLoads.gutterWeight.tooltip',
          name: ['geometryAndConstructionProject', 'liveLoads', 'gutterWeight'],
          addonAfter: MeasurementEnum.NM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 1000,
        },
        {
          label: 'projects.geometryAndConstruction.liveLoads.trussWeight.title',
          tooltip: 'projects.geometryAndConstruction.liveLoads.trussWeight.tooltip',
          name: ['geometryAndConstructionProject', 'liveLoads', 'trussWeight'],
          addonAfter: MeasurementEnum.N,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 999999,
        },
        {
          label: 'projects.geometryAndConstruction.liveLoads.windLoadReference.title',
          tooltip: 'projects.geometryAndConstruction.liveLoads.windLoadReference.tooltip',
          name: ['geometryAndConstructionProject', 'liveLoads', 'windLoadReference'],
          type: 'text',
          required: true,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.liveLoads.snowLoadReference.title',
          tooltip: 'projects.geometryAndConstruction.liveLoads.snowLoadReference.tooltip',
          name: ['geometryAndConstructionProject', 'liveLoads', 'snowLoadReference'],
          type: 'text',
          required: true,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.liveLoads.snowReductionByMeltingSystem.title',
          tooltip: 'projects.geometryAndConstruction.liveLoads.snowReductionByMeltingSystem.tooltip',
          name: ['geometryAndConstructionProject', 'liveLoads', 'snowReduction'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.liveLoads.temperatureInBeneathRoofDuringSnowfall.title',
          tooltip: 'projects.geometryAndConstruction.liveLoads.temperatureInBeneathRoofDuringSnowfall.tooltip',
          name: ['geometryAndConstructionProject', 'liveLoads', 'temperatureBeneathRoof'],
          type: 'select',
          required: true,
          disabled: false,
          defaultValue: '12',
          selectOptions: [
            {
              label: '12 °C',
              value: '12',
              key: '12',
            },
            {
              label: '16 °C',
              value: '16',
              key: '16',
            },
            {
              label: '18 °C',
              value: '18',
              key: '18',
            },
          ],
          hideIfBoolFalse: ['geometryAndConstructionProject', 'liveLoads', 'snowReduction'],
        },
      ],
    },
    {
      title: '',
      objectName: 'liveLoads',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.liveLoads.serviceLoad.title',
          tooltip: 'projects.geometryAndConstruction.liveLoads.serviceLoad.tooltip',
          name: ['geometryAndConstructionProject', 'liveLoads', 'serviceLoad'],
          addonAfter: MeasurementEnum.NM2,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 10000,
        },
        {
          label: 'projects.geometryAndConstruction.liveLoads.horizontalScreeningInside.title',
          tooltip: 'projects.geometryAndConstruction.liveLoads.horizontalScreeningInside.tooltip',
          name: ['geometryAndConstructionProject', 'liveLoads', 'horizontalScreeningInside'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.liveLoads.numberOfScreeningSystemsBRL.title',
          tooltip: 'projects.geometryAndConstruction.liveLoads.numberOfScreeningSystemsBRL.tooltip',
          name: ['geometryAndConstructionProject', 'liveLoads', 'numberOfScreeningSystemsBRL'],
          type: 'radio',
          required: true,
          disabled: false,
          selectOptions: [
            {
              label: '1',
              value: 1,
              key: 1,
            },
            {
              label: '2',
              value: 2,
              key: 2,
            },
            {
              label: '3',
              value: 3,
              key: 3,
            },
            {
              label: '4',
              value: 4,
              key: 4,
            },
          ],
          hideIfBoolFalse: ['geometryAndConstructionProject', 'liveLoads', 'horizontalScreeningInside'],
        },
        {
          label: 'projects.geometryAndConstruction.liveLoads.screenWireHorizontalSuspended.title',
          tooltip: 'projects.geometryAndConstruction.liveLoads.screenWireHorizontalSuspended.tooltip',
          name: ['geometryAndConstructionProject', 'liveLoads', 'screenWireHorizontalSuspended'],
          type: 'select',
          required: true,
          disabled: false,
          defaultValue: 'pullWire',
          selectOptions: [
            {
              label: 'Pull wire',
              value: 'pullWire',
              key: 'pullWire',
            },
            {
              label: 'Push pull tube',
              value: 'pushPullTube',
              key: 'pushPullTube',
            },
          ],
          hideIfBoolFalse: ['geometryAndConstructionProject', 'liveLoads', 'horizontalScreeningInside'],
        },
      ],
    },
  ],
};

export const cropWireSystemFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: 'cropWireSystem',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.cropWireSystem.cropsHangingAtTruss.title',
          tooltip: 'projects.geometryAndConstruction.cropWireSystem.cropsHangingAtTruss.tooltip',
          name: ['geometryAndConstructionProject', 'cropWireSystem', 'hangingAtTruss'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.cropWireSystem.cropWireHorizontalSuspension.title',
          tooltip: 'projects.geometryAndConstruction.cropWireSystem.cropWireHorizontalSuspension.tooltip',
          name: ['geometryAndConstructionProject', 'cropWireSystem', 'wireHorizontalSuspensionType'],
          type: 'select',
          required: true,
          disabled: false,
          defaultValue: 'no',
          selectOptions: [
            {
              label: 'No',
              value: 'no',
              key: 'no',
            },
            {
              label: 'Gable end',
              value: 'gableEnd',
              key: 'gableEnd',
            },
          ],
          hideIfBoolFalse: ['geometryAndConstructionProject', 'cropWireSystem', 'hangingAtTruss'],
        },
        {
          label: 'projects.geometryAndConstruction.cropWireSystem.maximumCropLoad.title',
          tooltip: 'projects.geometryAndConstruction.cropWireSystem.maximumCropLoad.tooltip',
          name: ['geometryAndConstructionProject', 'cropWireSystem', 'maxCropLoad'],
          addonAfter: MeasurementEnum.NM2,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 2000,
          hideIfBoolFalse: ['geometryAndConstructionProject', 'cropWireSystem', 'hangingAtTruss'],
        },
        {
          label: 'projects.geometryAndConstruction.cropWireSystem.cropLoadAtSnowConditions.title',
          tooltip: 'projects.geometryAndConstruction.cropWireSystem.cropLoadAtSnowConditions.tooltip',
          name: ['geometryAndConstructionProject', 'cropWireSystem', 'cropLoadSnowConditions'],
          addonAfter: MeasurementEnum.NM2,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 2000,
          hideIfBoolFalse: ['geometryAndConstructionProject', 'cropWireSystem', 'hangingAtTruss'],
        },
        {
          label: 'projects.geometryAndConstruction.cropWireSystem.positionSuspensionWireToTruss.title',
          tooltip: 'projects.geometryAndConstruction.cropWireSystem.positionSuspensionWireToTruss.tooltip',
          name: ['geometryAndConstructionProject', 'cropWireSystem', 'suspensionWirePosition'],
          type: 'select',
          required: true,
          disabled: false,
          defaultValue: 'diagonalNode',
          selectOptions: [
            {
              label: 'Diagonal node',
              value: 'diagonalNode',
              key: 'diagonalNode',
            },
            {
              label: 'Random bottom member truss',
              value: 'randomBottomMemberTruss',
              key: 'randomBottomMemberTruss',
            },
          ],
          hideIfBoolFalse: ['geometryAndConstructionProject', 'cropWireSystem', 'hangingAtTruss'],
        },
        {
          label: 'projects.geometryAndConstruction.cropWireSystem.distanceOfSuspendedWireCC.title',
          tooltip: 'projects.geometryAndConstruction.cropWireSystem.distanceOfSuspendedWireCC.tooltip',
          name: ['geometryAndConstructionProject', 'cropWireSystem', 'distanceSuspendedWireCC'],
          addonAfter: MeasurementEnum.MM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 3200,
          hideIfBoolFalse: ['geometryAndConstructionProject', 'cropWireSystem', 'hangingAtTruss'],
          hideIfSelectIsValue: {
            name: ['geometryAndConstructionProject', 'cropWireSystem', 'wireHorizontalSuspensionType'],
            value: 'gableEnd',
          },
        },
        {
          label: 'projects.geometryAndConstruction.cropWireSystem.loweringOfCropWires.title',
          tooltip: 'projects.geometryAndConstruction.cropWireSystem.loweringOfCropWires.tooltip',
          name: ['geometryAndConstructionProject', 'cropWireSystem', 'loweringCropWires'],
          addonAfter: MeasurementEnum.MM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 200,
          max: 9999,
          hideIfBoolFalse: ['geometryAndConstructionProject', 'cropWireSystem', 'hangingAtTruss'],
          hideIfSelectIsValue: {
            name: ['geometryAndConstructionProject', 'cropWireSystem', 'wireHorizontalSuspensionType'],
            value: 'gableEnd',
          },
        },
        {
          label: 'projects.geometryAndConstruction.cropWireSystem.minimumCropWireDip.title',
          tooltip: 'projects.geometryAndConstruction.cropWireSystem.minimumCropWireDip.tooltip',
          name: ['geometryAndConstructionProject', 'cropWireSystem', 'minCropwireDip'],
          addonAfter: MeasurementEnum.MM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 100,
          max: 9999,
          hideIfBoolFalse: ['geometryAndConstructionProject', 'cropWireSystem', 'hangingAtTruss'],
          hideIfSelectIsValue: {
            name: ['geometryAndConstructionProject', 'cropWireSystem', 'wireHorizontalSuspensionType'],
            value: 'gableEnd',
          },
        },
      ],
    },
    {
      title: 'projects.geometryAndConstruction.cropWireSystem.cultivation.title',
      objectName: 'cropWireSystem',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.cropWireSystem.cultivation.cultivationGutterAtTruss.title',
          tooltip: 'projects.geometryAndConstruction.cropWireSystem.cultivation.cultivationGutterAtTruss.tooltip',
          name: ['geometryAndConstructionProject', 'cropWireSystem', 'cultivationGutterTruss'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.cropWireSystem.cultivation.cultivationGutterAndMediumDeadLoad.title',
          tooltip: 'projects.geometryAndConstruction.cropWireSystem.cultivation.cultivationGutterAndMediumDeadLoad.tooltip',
          name: ['geometryAndConstructionProject', 'cropWireSystem', 'cultivationGutterMediumDeadLoad'],
          addonAfter: MeasurementEnum.NM2,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 2000,
          hideIfBoolFalse: ['geometryAndConstructionProject', 'cropWireSystem', 'cultivationGutterTruss'],
        },
        {
          label: 'projects.geometryAndConstruction.cropWireSystem.cultivation.positionSuspensionWireToTruss.title',
          tooltip: 'projects.geometryAndConstruction.cropWireSystem.cultivation.positionSuspensionWireToTruss.tooltip',
          name: ['geometryAndConstructionProject', 'cropWireSystem', 'cultivationSuspensionWirePosition'],
          type: 'select',
          required: true,
          disabled: false,
          defaultValue: 'diagonalNode',
          selectOptions: [
            {
              label: 'Diagonal node',
              value: 'diagonalNode',
              key: 'diagonalNode',
            },
            {
              label: 'Random bottom member truss',
              value: 'randomBottomMemberTruss',
              key: 'randomBottomMemberTruss',
            },
          ],
          hideIfBoolFalse: ['geometryAndConstructionProject', 'cropWireSystem', 'cultivationGutterTruss'],
        },
        {
          label: 'projects.geometryAndConstruction.cropWireSystem.cultivation.distanceOfSuspensionWireCC.title',
          tooltip: 'projects.geometryAndConstruction.cropWireSystem.cultivation.distanceOfSuspensionWireCC.tooltip',
          name: ['geometryAndConstructionProject', 'cropWireSystem', 'cultivationDistanceSuspendedWireCC'],
          addonAfter: MeasurementEnum.MM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 3200,
          hideIfBoolFalse: ['geometryAndConstructionProject', 'cropWireSystem', 'cultivationGutterTruss'],
        },
      ],
    },
  ],
};

export const loadsServicesAndMaintenanceFormStructure: FormType = {
  sections: [
    {
      title: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.transport.title',
      objectName: 'serviceAndMaintenanceLoads',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.transport.transportLoadIncidental.title',
          tooltip: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.transport.transportLoadIncidental.tooltip',
          name: ['geometryAndConstructionProject', 'serviceAndMaintenanceLoads', 'transportLoadIncidental'],
          addonAfter: MeasurementEnum.N,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 999999,
        },
        {
          label: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.transport.transportLoadPermanent.title',
          tooltip: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.transport.transportLoadPermanent.tooltip',
          name: ['geometryAndConstructionProject', 'serviceAndMaintenanceLoads', 'transportLoadPermanent'],
          addonAfter: MeasurementEnum.N,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 999999,
        },
        {
          label: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.transport.positionSuspensionToTruss.title',
          tooltip: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.transport.positionSuspensionToTruss.tooltip',
          name: ['geometryAndConstructionProject', 'serviceAndMaintenanceLoads', 'positionSuspensionWire'],
          type: 'select',
          required: true,
          disabled: false,
          defaultValue: 'diagonalNode',
          selectOptions: [
            {
              label: 'Diagonal node',
              value: 'diagonalNode',
              key: 'diagonalNode',
            },
            {
              label: 'Random bottom member truss',
              value: 'randomBottomMemberTruss',
              key: 'randomBottomMemberTruss',
            },
          ],
        },
        {
          label: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.transport.numberOfSuspensionPointsPerTruss.title',
          tooltip: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.transport.numberOfSuspensionPointsPerTruss.tooltip',
          name: ['geometryAndConstructionProject', 'serviceAndMaintenanceLoads', 'suspensionPointsPerTruss'],
          type: 'radio',
          required: true,
          disabled: false,
          defaultValue: '1',
          selectOptions: [
            {
              label: '1',
              value: 1,
              key: 1,
            },
            {
              label: '2',
              value: 2,
              key: 2,
            },
          ],
        },
      ],
    },
    {
      title: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.roofCleaner.title',
      objectName: 'serviceAndMaintenanceLoads',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.roofCleaner.title',
          tooltip: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.roofCleaner.tooltip',
          name: ['geometryAndConstructionProject', 'serviceAndMaintenanceLoads', 'roofCleaner'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.roofCleaner.roofCleanerDeadLoad.title',
          tooltip: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.roofCleaner.roofCleanerDeadLoad.tooltip',
          name: ['geometryAndConstructionProject', 'serviceAndMaintenanceLoads', 'roofCleanerDeadLoad'],
          addonAfter: MeasurementEnum.KG,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 999999,
          hideIfBoolFalse: ['geometryAndConstructionProject', 'serviceAndMaintenanceLoads', 'roofCleaner'],
        },
        {
          label: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.roofCleaner.roofCleanerWheelDistanceCC.title',
          tooltip: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.roofCleaner.roofCleanerWheelDistanceCC.tooltip',
          name: ['geometryAndConstructionProject', 'serviceAndMaintenanceLoads', 'roofCleaenerWheelDistanceCC'],
          addonAfter: MeasurementEnum.MM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 500,
          max: 5000,
          hideIfBoolFalse: ['geometryAndConstructionProject', 'serviceAndMaintenanceLoads', 'roofCleaner'],
        },
      ],
    },
    {
      title: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.serviceRail.title',
      objectName: 'serviceAndMaintenanceLoads',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.serviceRail.serviceRailProvision.title',
          tooltip: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.serviceRail.serviceRailProvision.tooltip',
          name: ['geometryAndConstructionProject', 'serviceAndMaintenanceLoads', 'serviceRailProvision'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.serviceRail.maxVerticalLoadAtGableEnd.title',
          tooltip: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.serviceRail.maxVerticalLoadAtGableEnd.tooltip',
          name: ['geometryAndConstructionProject', 'serviceAndMaintenanceLoads', 'maxVerticalLoadGableEnd'],
          addonAfter: MeasurementEnum.KG,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 999999,
          hideIfBoolFalse: ['geometryAndConstructionProject', 'serviceAndMaintenanceLoads', 'serviceRailProvision'],
        },
        {
          label: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.serviceRail.maxWeightForMaterials.title',
          tooltip: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.serviceRail.maxWeightForMaterials.tooltip',
          name: ['geometryAndConstructionProject', 'serviceAndMaintenanceLoads', 'maxMaterialWeight'],
          addonAfter: MeasurementEnum.KG,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 9999,
          hideIfBoolFalse: ['geometryAndConstructionProject', 'serviceAndMaintenanceLoads', 'serviceRailProvision'],
        },
        {
          label: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.serviceRail.dockWeight.title',
          tooltip: 'projects.geometryAndConstruction.loadsServicesAndMaintenance.serviceRail.dockWeight.tooltip',
          name: ['geometryAndConstructionProject', 'serviceAndMaintenanceLoads', 'dockWeight'],
          addonAfter: MeasurementEnum.KG,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 9999,
          hideIfBoolFalse: ['geometryAndConstructionProject', 'serviceAndMaintenanceLoads', 'serviceRailProvision'],
        },
      ],
    },
  ],
};

export const gableEndFormStructure: FormType = {
  sections: [
    {
      title: 'projects.geometryAndConstruction.gableEnd.base.title',
      objectName: 'gableEnd',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.gableEnd.base.nrRowsPurlins.title',
          tooltip: 'projects.geometryAndConstruction.gableEnd.base.nrRowsPurlins.tooltip',
          name: ['geometryAndConstructionProject', 'gableEnd', 'rowsofPurlins'],
          type: 'radio',
          required: true,
          disabled: false,
          defaultValue: '3',
          selectOptions: [
            {
              label: '2',
              value: 2,
              key: 2,
            },
            {
              label: '3',
              value: 3,
              key: 3,
            },
            {
              label: '4',
              value: 4,
              key: 4,
            },
            {
              label: '5',
              value: 5,
              key: 5,
            },
          ],
        },
        {
          label: 'projects.geometryAndConstruction.gableEnd.base.gableEndColumnGutterJoint.title',
          tooltip: 'projects.geometryAndConstruction.gableEnd.base.gableEndColumnGutterJoint.tooltip',
          name: ['geometryAndConstructionProject', 'gableEnd', 'gableEndColumnGutterJoint'],
          type: 'select',
          required: true,
          disabled: false,
          defaultValue: 'hinged',
          selectOptions: [
            {
              label: 'Hinged',
              value: 'hinged',
              key: 'hinged',
            },
            {
              label: 'Moment fixed',
              value: 'momentFixed',
              key: 'momentFixed',
            },
          ],
        },
        {
          label: 'projects.geometryAndConstruction.gableEnd.base.foundationType.title',
          tooltip: 'projects.geometryAndConstruction.gableEnd.base.foundationType.tooltip',
          name: ['geometryAndConstructionProject', 'gableEnd', 'foundationType'],
          type: 'select',
          required: true,
          disabled: false,
          defaultValue: 'beam',
          selectOptions: [
            {
              label: 'Beam',
              value: 'beam',
              key: 'beam',
            },
            {
              label: 'Prefab wall',
              value: 'prefabWall',
              key: 'prefabWall',
            },
          ],
        },
        {
          label: 'projects.geometryAndConstruction.gableEnd.base.deadLoadFacade.title',
          tooltip: 'projects.geometryAndConstruction.gableEnd.base.deadLoadFacade.tooltip',
          name: ['geometryAndConstructionProject', 'gableEnd', 'deadLoadFacade'],
          addonAfter: MeasurementEnum.NM2,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 2000,
        },
      ],
    },
    {
      title: 'projects.geometryAndConstruction.gableEnd.cantilever.title',
      objectName: 'gableEnd',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.gableEnd.cantilever.cantilever.title',
          tooltip: 'projects.geometryAndConstruction.gableEnd.cantilever.cantilever.tooltip',
          name: ['geometryAndConstructionProject', 'gableEnd', 'cantilever'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.gableEnd.cantilever.pipesDeadLoadToCantilever.title',
          tooltip: 'projects.geometryAndConstruction.gableEnd.cantilever.pipesDeadLoadToCantilever.tooltip',
          name: ['geometryAndConstructionProject', 'gableEnd', 'pipeDeadLoadCantilever'],
          addonAfter: MeasurementEnum.NM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 9999,
          hideIfBoolFalse: ['geometryAndConstructionProject', 'gableEnd', 'cantilever'],
        },
        {
          label: 'projects.geometryAndConstruction.gableEnd.cantilever.columnCantileverOverhang.title',
          tooltip: 'projects.geometryAndConstruction.gableEnd.cantilever.columnCantileverOverhang.tooltip',
          name: ['geometryAndConstructionProject', 'gableEnd', 'columnCantileverOverhang'],
          addonAfter: MeasurementEnum.MM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 1000,
          hideIfBoolFalse: ['geometryAndConstructionProject', 'gableEnd', 'cantilever'],
        },
        {
          label: 'projects.geometryAndConstruction.gableEnd.cantilever.cantileverPositionUnderGutter.title',
          tooltip: 'projects.geometryAndConstruction.gableEnd.cantilever.cantileverPositionUnderGutter.tooltip',
          name: ['geometryAndConstructionProject', 'gableEnd', 'cantileverPositionUnderGutter'],
          addonAfter: MeasurementEnum.MM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 15000,
          hideIfBoolFalse: ['geometryAndConstructionProject', 'gableEnd', 'cantilever'],
        },
      ],
    },
    {
      title: 'projects.geometryAndConstruction.gableEnd.suspensionFromWires.title',
      objectName: 'gableEnd',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.gableEnd.suspensionFromWires.hoistHeatingPipesSuspendedFromWires.title',
          tooltip: 'projects.geometryAndConstruction.gableEnd.suspensionFromWires.hoistHeatingPipesSuspendedFromWires.tooltip',
          name: ['geometryAndConstructionProject', 'gableEnd', 'hoistingHeatingPipesSuspended'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.gableEnd.suspensionFromWires.irrigationPupesSuspendedFromWires.title',
          tooltip: 'projects.geometryAndConstruction.gableEnd.suspensionFromWires.irrigationPupesSuspendedFromWires.tooltip',
          name: ['geometryAndConstructionProject', 'gableEnd', 'irrigationPipesSuspended'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.gableEnd.suspensionFromWires.illuminationSuspendedFromWires.title',
          tooltip: 'projects.geometryAndConstruction.gableEnd.suspensionFromWires.illuminationSuspendedFromWires.tooltip',
          name: ['geometryAndConstructionProject', 'gableEnd', 'illuminationSuspended'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
      ],
    },
  ],
};

export const sideWallFormStructure: FormType = {
  sections: [
    {
      title: 'projects.geometryAndConstruction.sideWall.base.title',
      objectName: 'sideWall',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.sideWall.base.nrRowsPurlins.title',
          tooltip: 'projects.geometryAndConstruction.sideWall.base.nrRowsPurlins.tooltip',
          name: ['geometryAndConstructionProject', 'sideWall', 'rowsOfPurlins'],
          type: 'radio',
          required: true,
          disabled: false,
          defaultValue: '3',
          selectOptions: [
            {
              label: '2',
              value: 2,
              key: 2,
            },
            {
              label: '3',
              value: 3,
              key: 3,
            },
            {
              label: '4',
              value: 4,
              key: 4,
            },
            {
              label: '5',
              value: 5,
              key: 5,
            },
          ],
        },
        {
          label: 'projects.geometryAndConstruction.sideWall.base.intermediateColumn.title',
          tooltip: 'projects.geometryAndConstruction.sideWall.base.intermediateColumn.tooltip',
          name: ['geometryAndConstructionProject', 'sideWall', 'intermediateColumn'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.sideWall.base.deadLoadFacade.title',
          tooltip: 'projects.geometryAndConstruction.sideWall.base.deadLoadFacade.tooltip',
          name: ['geometryAndConstructionProject', 'sideWall', 'deadLoadFacade'],
          addonAfter: MeasurementEnum.NM2,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 2000,
        },
        {
          label: 'projects.geometryAndConstruction.sideWall.base.foundationType.title',
          tooltip: 'projects.geometryAndConstruction.sideWall.base.foundationType.tooltip',
          name: ['geometryAndConstructionProject', 'sideWall', 'foundationType'],
          type: 'select',
          required: true,
          disabled: false,
          defaultValue: 'beam',
          selectOptions: [
            {
              label: 'Beam',
              value: 'beam',
              key: 'beam',
            },
            {
              label: 'Prefab wall',
              value: 'prefabWall',
              key: 'prefabWall',
            },
          ],
        },
      ],
    },
    {
      title: 'projects.geometryAndConstruction.sideWall.cantilever.title',
      objectName: 'sideWall',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.sideWall.cantilever.cantilever.title',
          tooltip: 'projects.geometryAndConstruction.sideWall.cantilever.cantilever.tooltip',
          name: ['geometryAndConstructionProject', 'sideWall', 'cantilever'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.sideWall.cantilever.pipesDeadLoadToCantilever.title',
          tooltip: 'projects.geometryAndConstruction.sideWall.cantilever.pipesDeadLoadToCantilever.tooltip',
          name: ['geometryAndConstructionProject', 'sideWall', 'pipeDeadLoadCantilever'],
          addonAfter: MeasurementEnum.NM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 9999,
          hideIfBoolFalse: ['geometryAndConstructionProject', 'sideWall', 'cantilever'],
        },
        {
          label: 'projects.geometryAndConstruction.sideWall.cantilever.columnCantileverOverhang.title',
          tooltip: 'projects.geometryAndConstruction.sideWall.cantilever.columnCantileverOverhang.tooltip',
          name: ['geometryAndConstructionProject', 'sideWall', 'columnCantileverOverhang'],
          addonAfter: MeasurementEnum.MM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 1000,
          hideIfBoolFalse: ['geometryAndConstructionProject', 'sideWall', 'cantilever'],
        },
        {
          label: 'projects.geometryAndConstruction.sideWall.cantilever.cantileverPositionUnderGutter.title',
          tooltip: 'projects.geometryAndConstruction.sideWall.cantilever.cantileverPositionUnderGutter.tooltip',
          name: ['geometryAndConstructionProject', 'sideWall', 'cantileverPositionUnderGutter'],
          addonAfter: MeasurementEnum.MM,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 15000,
          hideIfBoolFalse: ['geometryAndConstructionProject', 'sideWall', 'cantilever'],
        },
      ],
    },
    {
      title: 'projects.geometryAndConstruction.sideWall.suspensionFromWires.title',
      objectName: 'sideWall',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.sideWall.suspensionFromWires.hoistHeatingPipesSuspendedFromWires.title',
          tooltip: 'projects.geometryAndConstruction.sideWall.suspensionFromWires.hoistHeatingPipesSuspendedFromWires.tooltip',
          name: ['geometryAndConstructionProject', 'sideWall', 'hoistingHeatingPipesSuspended'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.sideWall.suspensionFromWires.irrigationPupesSuspendedFromWires.title',
          tooltip: 'projects.geometryAndConstruction.sideWall.suspensionFromWires.irrigationPupesSuspendedFromWires.tooltip',
          name: ['geometryAndConstructionProject', 'sideWall', 'irrigationPipesSuspended'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.sideWall.suspensionFromWires.illuminationSuspendedFromWires.title',
          tooltip: 'projects.geometryAndConstruction.sideWall.suspensionFromWires.illuminationSuspendedFromWires.tooltip',
          name: ['geometryAndConstructionProject', 'sideWall', 'illuminationSuspended'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
      ],
    },
  ],
};

export const aluminiumGutterFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: 'aluminiumGutter',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.aluminiumGutter.type.title',
          tooltip: 'projects.geometryAndConstruction.aluminiumGutter.type.tooltip',
          name: ['geometryAndConstructionProject', 'aluminiumGutter', 'type'],
          type: 'text',
          required: true,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.aluminiumGutter.desiredDrainageGradient.title',
          tooltip: 'projects.geometryAndConstruction.aluminiumGutter.desiredDrainageGradient.tooltip',
          name: ['geometryAndConstructionProject', 'aluminiumGutter', 'desiredDrainageGradient'],
          addonAfter: MeasurementEnum.PERCENTAGE,
          type: 'number',
          step: 0.001,
          required: true,
          disabled: false,
          min: 0,
          max: 2,
        },
        {
          label: 'projects.geometryAndConstruction.aluminiumGutter.terrainGradientGutterDirection.title',
          tooltip: 'projects.geometryAndConstruction.aluminiumGutter.terrainGradientGutterDirection.tooltip',
          name: ['geometryAndConstructionProject', 'aluminiumGutter', 'terrainGradientGutterDirection'],
          addonAfter: MeasurementEnum.PERCENTAGE,
          type: 'number',
          step: 0.1,
          required: true,
          disabled: false,
          min: 0,
          max: 2,
        },
        {
          label: 'projects.geometryAndConstruction.aluminiumGutter.drainageSingleOrDoubleSided.title',
          tooltip: 'projects.geometryAndConstruction.aluminiumGutter.drainageSingleOrDoubleSided.tooltip',
          name: ['geometryAndConstructionProject', 'aluminiumGutter', 'drainage'],
          type: 'select',
          required: true,
          disabled: false,
          defaultValue: 'singleSided',
          selectOptions: [
            {
              label: 'Single sided',
              value: 'singleSided',
              key: 'singleSided',
            },
            {
              label: 'Double sided',
              value: 'doubleSided',
              key: 'doubleSided',
            },
          ],
        },
      ],
    },
  ],
};

export const rainwaterSystemFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: 'rainwaterSystem',
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.rainwaterSystem.rainIntensity.title',
          tooltip: 'projects.geometryAndConstruction.rainwaterSystem.rainIntensity.tooltip',
          name: ['geometryAndConstructionProject', 'rainwaterSystem', 'rainIntensity'],
          addonAfter: MeasurementEnum.MMH,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 10,
          max: 300,
        },
        {
          label: 'projects.geometryAndConstruction.rainwaterSystem.collectionFromGutterBy.title',
          tooltip: 'projects.geometryAndConstruction.rainwaterSystem.collectionFromGutterBy.tooltip',
          name: ['geometryAndConstructionProject', 'rainwaterSystem', 'gutterCollection'],
          type: 'select',
          required: true,
          disabled: false,
          defaultValue: 'column',
          selectOptions: [
            {
              label: 'Column',
              value: 'column',
              key: 'column',
            },
            {
              label: 'Downpipe',
              value: 'downpipe',
              key: 'downpipe',
            },
          ],
        },
        {
          label: 'projects.geometryAndConstruction.rainwaterSystem.collectionManifoldInside.title',
          tooltip: 'projects.geometryAndConstruction.rainwaterSystem.collectionManifoldInside.tooltip',
          name: ['geometryAndConstructionProject', 'rainwaterSystem', 'collectionManifoldInside'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.rainwaterSystem.waterDrainsTo.title',
          tooltip: 'projects.geometryAndConstruction.rainwaterSystem.waterDrainsTo.tooltip',
          name: ['geometryAndConstructionProject', 'rainwaterSystem', 'waterDrain'],
          type: 'select',
          required: true,
          disabled: false,
          defaultValue: 'basin',
          selectOptions: [
            {
              label: 'Basin',
              value: 'basin',
              key: 'basin',
            },
            {
              label: 'Ditch',
              value: 'ditch',
              key: 'ditch',
            },
            {
              label: 'Surface',
              value: 'surface',
              key: 'surface',
            },
          ],
        },
        {
          label: 'projects.geometryAndConstruction.rainwaterSystem.lengthCollectionManifold.title',
          tooltip: 'projects.geometryAndConstruction.rainwaterSystem.lengthCollectionManifold.tooltip',
          name: ['geometryAndConstructionProject', 'rainwaterSystem', 'lengthCollectionManifold'],
          addonAfter: MeasurementEnum.M,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 9999,
        },
        {
          label: 'projects.geometryAndConstruction.rainwaterSystem.drainOutletLevel.title',
          tooltip: 'projects.geometryAndConstruction.rainwaterSystem.drainOutletLevel.tooltip',
          name: ['geometryAndConstructionProject', 'rainwaterSystem', 'drainOutletLevel'],
          addonAfter: MeasurementEnum.M,
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 0,
          max: 9,
        },
        {
          label: 'projects.geometryAndConstruction.rainwaterSystem.emptyPumpLine.title',
          tooltip: 'projects.geometryAndConstruction.rainwaterSystem.emptyPumpLine.tooltip',
          name: ['geometryAndConstructionProject', 'rainwaterSystem', 'emptyPumpLine'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.rainwaterSystem.evacuationWorksIncludedQuotation.title',
          tooltip: 'projects.geometryAndConstruction.rainwaterSystem.evacuationWorksIncludedQuotation.tooltip',
          name: ['geometryAndConstructionProject', 'rainwaterSystem', 'excavationWorksIncluded'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.geometryAndConstruction.rainwaterSystem.ventCollectionManifold.title',
          tooltip: 'projects.geometryAndConstruction.rainwaterSystem.ventCollectionManifold.tooltip',
          name: ['geometryAndConstructionProject', 'rainwaterSystem', 'ventCollectionManifold'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
      ],
    },
  ],
};

import { faHouse } from '@fortawesome/pro-solid-svg-icons';
import { FormType } from '../types/project.types';

export const generalGlazenStadFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: '',
      icon: faHouse,
      span: 24,
      fields: [
        {
          label: 'projects.generalInformation.project.contractor.title',
          tooltip: 'projects.generalInformation.project.contractor.tooltip',
          name: ['project', 'contractor', 'name'],
          type: 'text',
          required: true,
          disabled: true,
          requiredMessage: 'projects.generalInformation.project.projectName.requiredMessage',
        },
        {
          label: 'projects.generalInformation.project.client.title',
          tooltip: 'projects.generalInformation.project.client.tooltip',
          name: ['project', 'generalInformation', 'client'],
          type: 'text',
          required: true,
          disabled: true,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
        {
          label: 'projects.glazenStad.generalGlazenStad.projectName.title',
          tooltip: 'projects.glazenStad.generalGlazenStad.projectName.tooltip',
          name: ['glazenStadProject', 'projectName'],
          type: 'text',
          required: true,
          disabled: false,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
        {
          label: 'projects.generalInformation.location.locationPlace.title',
          tooltip: 'projects.generalInformation.location.locationPlace.tooltip',
          name: ['project', 'generalInformation', 'city'],
          type: 'text',
          required: true,
          disabled: true,
          requiredMessage: 'projects.generalInformation.project.constructionStartDate.requiredMessage',
        },
      ],
    },
    {
      title: '',
      objectName: '',
      icon: faHouse,
      span: 24,
      fields: [
        {
          label: 'projects.glazenStad.generalGlazenStad.description.title',
          tooltip: 'projects.glazenStad.generalGlazenStad.description.tooltip',
          name: ['glazenStadProject', 'remarks'],
          type: 'text',
          required: true,
          disabled: false,
        },
        {
          label: 'projects.generalInformation.location.latitude.title',
          tooltip: 'projects.generalInformation.location.latitude.tooltip',
          name: ['project', 'generalInformation', 'latitude'],
          type: 'number',
          min: -90,
          max: 90,
          step: 0.00000001,
          dotSeparator: '.',
          required: true,
          disabled: true,
          requiredMessage: 'projects.generalInformation.location.latitude.requiredMessage',
          defaultValue: 52.0842635,
        },
        {
          label: 'projects.generalInformation.location.longitude.title',
          tooltip: 'projects.generalInformation.location.longitude.tooltip',
          name: ['project', 'generalInformation', 'longitude'],
          type: 'number',
          min: -180,
          max: 180,
          step: 0.00000001,
          dotSeparator: '.',
          required: true,
          disabled: true,
          requiredMessage: 'projects.generalInformation.location.longitude.requiredMessage',
          defaultValue: 5.0824915,
        },
      ],
    },
  ],
};

import { Layout, Skeleton, message } from 'antd';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import KISHeader from '../header';
import styles from './baseLayout.module.scss';
import { atoms } from '@/shared/atoms';
import { useAtomValue } from 'jotai';

export const BaseLayout = (): JSX.Element => {
  const [, contextHolder] = message.useMessage();
  const keycloak = useAtomValue(atoms.auth.keycloakInstance);
  return (
    <div>
      <Suspense fallback={<Skeleton active loading />}>
        <Layout className={styles.layout}>
          <KISHeader userName={`${keycloak.idTokenParsed?.name}`} userCompany={''} />
          <div className={styles.content}>
            <Outlet />
          </div>
        </Layout>
      </Suspense>
      {contextHolder}
    </div>
  );
};

// ImportModal.tsx
import { faInfoCircle, faUpFromBracket } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, Form, Input, Select, Tooltip, Upload, UploadFile, UploadProps } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../dataExchange.module.scss';

export const ImportModal: FC = () => {
  const { t } = useTranslation();
  const [importFileList, setImportFileList] = useState<UploadFile[]>([]);
  const dummyRequest = ({ _, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };
  const importProps: UploadProps = {
    accept: '.ckd,.png',
    headers: {
      authorization: 'authorization-text',
    },
    maxCount: 1,
    onChange(info) {
      setImportFileList(info.fileList);
    },
  };

  return (
    <>
      <Divider />
      <Form.Item name={'files'}>
        {/* @ts-ignore */}
        <Upload customRequest={dummyRequest} {...importProps} fileList={importFileList}>
          <Button icon={<FontAwesomeIcon icon={faUpFromBracket} />}>&nbsp;{t('actions.clickToImport')}</Button>
        </Upload>
      </Form.Item>
      <Form.Item required name={'extension'}>
        <Select
          options={[
            {
              label: '.ckd (Casta Kassen Definition)',
              value: 'ckd',
            },
          ]}
          style={{ width: '100%' }}
        />
      </Form.Item>
      <div className={styles.modalForm}>
        <Form.Item name={'information'}>
          <Input />
        </Form.Item>
        <Tooltip title={t('projects.dataExchange.import.modal.tooltip')} placement="right">
          <FontAwesomeIcon icon={faInfoCircle} />
        </Tooltip>
      </div>
      <Divider />
    </>
  );
};

export default ImportModal;

import { verifyById } from '@/modules/project/model';
import { faArrowsFromLine, faArrowsToLine } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, notification } from 'antd';
import { t } from 'i18next';
import { FC, useEffect, useState } from 'react';
import { getVerifyItems } from '../../project.data';
import { KISCollapse } from '../../shared';
import { VerifyDataType } from '@/modules/project/types/project.types';
import { Spinal } from '@/shared/components';

export const VerifyDataModal: FC<{ id: string }> = ({ id }) => {
  const [initializing, setInitializing] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [toggleClose, setToggleClose] = useState(true);
  const [data, setData] = useState<VerifyDataType>();
  useEffect(() => {
    const abortController = new AbortController();
    verifyById(id, abortController.signal)
      .then((data: VerifyDataType) => {
        setData(data);
      })
      .catch(err => {
        setErrorMessage(err.response.status === 424 ? err.message : 'Oops! something went wrong...');
        notification.error(err);
      })
      .finally(() => {
        setInitializing(false);
      });
    return () => {
      abortController.abort();
    };
  }, [id]);

  return (
    <Spinal loading={false} initializing={initializing}>
      <Button style={{ float: 'right' }} type="primary" onClick={() => setToggleClose(!toggleClose)}>
        <FontAwesomeIcon
          icon={toggleClose ? faArrowsToLine : faArrowsFromLine}
          style={{
            marginRight: '.5rem',
          }}
        />
        {toggleClose ? t('actions.closeAll') : t('actions.openAll')}
      </Button>
      <Divider />
      {data ? <KISCollapse collapseItems={getVerifyItems(data)} toggleClose={toggleClose} /> : errorMessage}
      <Divider />
    </Spinal>
  );
};

export default VerifyDataModal;

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from 'antd';

import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';

import 'leaflet/dist/leaflet.css';

import { KISForm, KISMap, ProjectCardTitle } from '../shared';

import { generalFormStructure } from '@/modules/project/data/formStructureGeometry.data';
import { RightsEnum } from '@/modules/project/types/project.types';
import { isDisabled } from '@/shared/utilities/checkDisabled';
import styles from '../project.module.scss';

export const EditProject: FC<{ auth: number }> = ({ auth }) => {
  const { t } = useTranslation();

  return (
    <Card
      className={styles.card}
      title={<ProjectCardTitle className={styles.title} icon={faCircleInfo} title={t('projects.generalInformation.title')} />}
      style={{ width: '100%' }}>
      <KISForm disableFields={isDisabled(auth, [RightsEnum.READ])} maxFormHeight="800px" formStructure={generalFormStructure} />
      <KISMap />
    </Card>
  );
};

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { NamePath } from 'antd/es/form/interface';

export enum ResultEnum {
  READY_TO_PROCESS = 1,
  PROCESSED = 2,
  FAILED = 3,
}
export enum RightsEnum {
  OWNER = 1,
  READ = 3,
  WRITE = 2,
  NONE = 0,
}
export enum ProjectStatusEnum {
  CONCEPT = 0,
  OFFER = 1,
  PROCESSED = 2,
}

export enum MeasurementEnum {
  M2 = 'm2',
  M = 'm',
  KG = 'kg',
  KGHAH = 'kg/ha/h',
  MM = 'mm',
  PERCENTAGE = '%',
  DEGREE = '°',
  DEGREEN = '°N',
  DEGREEE = '°E',
  CELCIUS = '°C',
  N = 'N',
  NM2 = 'N/m2',
  NM = 'N/m',
  MMH = 'mm/h',
  HA = 'ha',
  M3HM2 = 'm3/h/m2',
  M3M2H = 'm3/m2/h',
  WM2 = 'W/m2',
  M3HA = 'm3/ha',
  GRM2HR = 'gr/m2/hr',
}

export interface ProjectWrapperProps {
  projectType: 'new' | 'edit';
}

export interface FieldType {
  label: string;
  name: NamePath;
  placeholder?: string;
  type: 'text' | 'date' | 'yearlessdate' | 'textarea' | 'select' | 'select-country' | 'checkbox' | 'radio' | 'number';
  required: boolean;
  requiredMessage?: string;
  disabled: boolean;
  addonAfter?: MeasurementEnum;
  min?: number;
  max?: number;
  dotSeparator?: '.' | ',';
  step?: number;
  selectOptions?: {
    key: any;
    label: string;
    value: any;
  }[];
  defaultValue?: any;
  tooltip?: string;
  hideIfBoolFalse?: NamePath;
  hideIfSelectIsValue?: {
    name: NamePath;
    value: string;
  };
  hidden?: boolean;
}

export interface FormType {
  sections: {
    title: string;
    objectName: string;
    icon?: IconDefinition;
    span: number;
    fields: FieldType[];
  }[];
}

export interface KisAuthorization {
  type: 'add' | 'edit';
  id: string;
  companyName: string;
  companyId: string;
  general: number;
  geometryAndConstruction: number;
  siom: number;
  glazenStad: number;
}

export type GeneralInformation = {
  client: string;
  projectId: string;
  projectName: string;
  constructionStartDate: string;
  internalProjectCode: string;
  address: string;
  city: string;
  country: string;
  longitude: number;
  latitude: number;
  altitude: number;
  orientation: number;
  type: string;
  remarks: string;
  id: string;
  updatedBy: string;
  updateDateTime: string;
};

export interface SiomTemplate {
  templateName: string;
  templateDescription: string;
  filename: string;
  id: string;
  updatedBy: string;
  updateDateTime: string;
}

export interface SiomResponse {
  heatingEnergy: number;
  heatingEnergyUseDryFruit: number;
  heatingEnergyUseFreshFruit: number;
  electricalEnergy: number;
  electricalEnergyUseDryFruit: number;
  electricalEnergyUseFreshFruit: number;
  waterUsed: number;
  waterUseDryFruit: number;
  waterUseFreshFruit: number;
  cO2Used: number;
  cO2DosingDryFruit: number;
  cO2DosingFreshFruit: number;
  dryMatter: number;
  photosynthesis: number;
  cO2Emission: number;
  cO2EmissionsDryFruit: number;
  cO2EmissionsFreshFruit: number;
  climateIndex: number;
  climateIndexPerMonth: {
    jan: number;
    feb: number;
    mar: number;
    apr: number;
    may: number;
    jun: number;
    jul: number;
    aug: number;
    sep: number;
    oct: number;
    nov: number;
    dec: number;
  };
}

export type HistoryType = {
  date: string;
  activity: string;
  result: string;
  name: string;
  info: string;
};

export type Company = {
  hortivationHubId: string;
  name: string;
  slug: string;
  domain: string;
  kvkNumber: string;
  id: string;
  updatedBy: string;
  updateDateTime: string;
};

export type GlobalForm = {
  project: ProjectType;
  authorization: AuthorizationType;
  geometryAndConstructionProject: GeometryAndConstructionType;
  siomProject: SiomProjectType;
  glazenStadProject: GlazenStadProjectType;
};

export type ProjectType = {
  contractor: {
    hortivationHubId: string;
    name: string;
    slug: string;
    domain: string;
    kvkNumber: string;
  };
  generalInformation: {
    client: string;
    projectId: string;
    status: number;
    projectName: string;
    constructionStartDate: string;
    internalProjectCode: string;
    address: string;
    city: string;
    country: string;
    longitude: number;
    latitude: number;
    altitude: number;
    orientation: number;
    type: string;
    remarks: string;
  };
};

export type AuthorizationType = {
  general: number;
  geometryAndConstruction: number;
  siom: number;
  glazenStad: number;
};

export type GeometryAndConstructionType = {
  geometryGenericDesign: {
    width: number;
    baysPerSpan: number;
    numberOfSpans: number;
    sectionLength: number;
    endSectionLength: number;
    pathSectionLength: number;
    gutterLength: number;
    facadeColumnHeight: number;
    trussHeight: number;
    trussLowering: number;
    foundationHeight: number;
    foundationType: string;
    roofAngle: number;
    roofGlass: number;
    roofGlassEndZone: number;
    gableEndGlass: number;
    sideWallGlass: number;
    remarks: string;
  };
  innerField: {
    columnHeight: number;
    foundationHeight: number;
    foundationPileType: string;
    remarks: string;
  };
  partitionsInside: {
    bayPartitions: number;
    partitionLength: number;
    remarks: string;
  };
  groundAndFoundation: {
    soilType: string;
    lowestGroundwaterLevel: number;
    highestGroundwaterLevel: number;
    conePenetrationTest: boolean;
    remarks: string;
  };
  liveLoads: {
    roofSystemType: string;
    roofDeadLoad: number;
    gutterWeight: number;
    trussWeight: number;
    windLoadReference: string;
    snowLoadReference: string;
    snowReduction: boolean;
    temperatureBeneathRoof: string;
    serviceLoad: number;
    horizontalScreeningInside: boolean;
    numberOfScreeningSystemsBRL: number;
    screenWireHorizontalSuspended: string;
  };
  cropWireSystem: {
    hangingAtTruss: boolean;
    maxCropLoad: number;
    cropLoadSnowConditions: number;
    wireHorizontalSuspensionType: string;
    suspensionWirePosition: string;
    distanceSuspendedWireCC: number;
    loweringCropWires: number;
    minCropwireDip: number;
    cultivationGutterTruss: boolean;
    cultivationGutterMediumDeadLoad: number;
    cultivationSuspensionWirePosition: string;
    cultivationDistanceSuspendedWireCC: number;
  };
  serviceAndMaintenanceLoads: {
    transportLoadIncidental: number;
    transportLoadPermanent: number;
    positionSuspensionWire: string;
    suspensionPointsPerTruss: number;
    roofCleaner: boolean;
    roofCleanerDeadLoad: number;
    roofCleaenerWheelDistanceCC: number;
    serviceRailProvision: boolean;
    maxVerticalLoadGableEnd: number;
    maxMaterialWeight: number;
    dockWeight: number;
  };
  gableEnd: {
    rowsofPurlins: number;
    gableEndColumnGutterJoint: string;
    deadLoadFacade: number;
    foundationType: string;
    cantilever: boolean;
    pipeDeadLoadCantilever: number;
    columnCantileverOverhang: number;
    cantileverPositionUnderGutter: number;
    hoistingHeatingPipesSuspended: boolean;
    irrigationPipesSuspended: boolean;
    illuminationSuspended: boolean;
  };
  sideWall: {
    rowsOfPurlins: number;
    intermediateColumn: boolean;
    deadLoadFacade: number;
    foundationType: string;
    cantilever: boolean;
    pipeDeadLoadCantilever: number;
    columnCantileverOverhang: number;
    cantileverPositionUnderGutter: number;
    hoistingHeatingPipesSuspended: boolean;
    irrigationPipesSuspended: boolean;
    illuminationSuspended: boolean;
  };
  aluminiumGutter: {
    type: string;
    desiredDrainageGradient: number;
    terrainGradientGutterDirection: number;
    drainage: string;
  };
  rainwaterSystem: {
    rainIntensity: number;
    gutterCollection: string;
    collectionManifoldInside: boolean;
    waterDrain: string;
    lengthCollectionManifold: number;
    drainOutletLevel: number;
    emptyPumpLine: boolean;
    excavationWorksIncluded: boolean;
    ventCollectionManifold: boolean;
  };
};

export type SiomProjectType = {
  general: {
    projectName: string;
    description: string;
  };
  construction: {
    greenhouseArea: number;
  };
  semiClosedSystem: {
    isSemiClosedSystem: boolean;
    ventilationCapacity: string;
    minimumVentilationRate: number;
    maximumVentilationRate: number;
  };
  crop: {
    cropProduced: string;
    cropPlantingDate: string;
    cropRemovalDate: string;
  };
  heating: {
    boiler: boolean;
    boilerPower: number;
    buffer: boolean;
    bufferCapacity: number;
  };
  ventilation: {
    ventilatingWindowsPerSection: number;
    depthVentilationWindow: number;
  };
  screens: {
    energyScreen: boolean;
    shadingScreen: boolean;
    blackoutScreen: boolean;
    insectScreen: boolean;
  };
  carbonDioxide: {
    useCnumber2Fertilisation: string;
    rateCnumber2Fertilisation: number;
  };
  cooling: {
    padAndFan: boolean;
    maxVentilationRatePadAndFan: number;
    fogging: boolean;
    flowCapacityFogging: number;
    activeCooling: boolean;
    electricalCoolingPower: number;
  };
  artificialLighting: {
    topLightsHPS: boolean;
    topLightsHPSPower: number;
    topLightsLED: boolean;
    topLightsLEDPower: number;
    interlightsLED: boolean;
    interlightsLEDPower: number;
  };
  waterManagementSystem: {
    undergroundWaterSource: boolean;
    undergroundWaterQuality: number;
    desalinationByReverseOsmosis: boolean;
    waterDisinfectionSystem: boolean;
  };
  photovoltaics: {
    pvInstallation: string;
    totalAreaPVElements: number;
  };
  template: {
    templateName: string;
    templateDescription: string;
    filename: string;
    id: string;
  };
};

export type GlazenStadProjectType = {
  projectName: string;
  remarks: string;
  id: string;
  updatedBy: string;
  updateDateTime: string;
};

export type VerifyObjectType = {
  prop: string;
  kisValue: any;
  castaValue: any;
};

export type VerifyDataType = {
  geometryGenericDesign: VerifyObjectType[];
  innerField: VerifyObjectType[];
  partitionsInside: VerifyObjectType[];
  groundAndFoundation: VerifyObjectType[];
  liveLoads: VerifyObjectType[];
  cropWireSystem: VerifyObjectType[];
  serviceAndMaintenanceLoads: VerifyObjectType[];
  gableEnd: VerifyObjectType[];
  sideWall: VerifyObjectType[];
  aluminiumGutter: VerifyObjectType[];
  rainwaterSystem: VerifyObjectType[];
};

import { SiomResponse } from '@/modules/project/types/project.types';
import { Col, Row } from 'antd';
import quickChart from 'quickchart-js';
import { FC, useEffect, useState } from 'react';
import { getBackgroundColors } from './siom-output-logic';
import styles from './styles.module.scss';
interface SiomOutputProps {
  data: SiomResponse;
}

const SiomOutput: FC<SiomOutputProps> = ({ data }) => {
  const [chart, setChart] = useState('');
  useEffect(() => {
    const chart = new quickChart();
    chart.setWidth(170);
    chart.setHeight(170);
    chart.setVersion('2.9.4');
    chart.setConfig({
      type: 'doughnut',
      data: {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
          {
            data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            backgroundColor: getBackgroundColors(data.climateIndexPerMonth),
          },
        ],
        labelObj: data.climateIndexPerMonth,
      },
      options: {
        legend: {
          display: false,
        },
        plugins: {
          datalabels: {
            display: true,
            formatter: (value, ctx) => {
              const labelObj = ctx.chart.data.labelObj;
              const label: string = ctx.chart.data.labels[ctx.dataIndex];
              return `${label}\n${labelObj[label.toLowerCase()]}%`;
            },
            font: {
              size: 9,
              family: 'Roboto',
            },
            color: '#000',
          },
          doughnutlabel: {
            labels: [
              {
                text: `${data.climateIndex.toFixed(2)}%`,
                font: {
                  size: 18,
                  weight: 'bold',
                  family: 'Roboto',
                },
              },
            ],
          },
        },
      },
    });
    const url = chart.getUrl();
    setChart(url);
  }, [data.climateIndex, data.climateIndexPerMonth]);

  return (
    <>
      <table className={styles.tableClass}>
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }} className={styles.column}>
              KPI Performance Indicators
            </th>
            <th style={{ paddingRight: 15 }} className={styles.column}>
              Per greenhouse area
            </th>
            <th style={{ paddingRight: 30 }} className={styles.column}>
              Per kg dry fruit
            </th>
            <th style={{ paddingRight: 20 }} className={styles.column}>
              Per kg fresh fruit
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={styles.column1}>
              <td>1</td>
              <td>Energy heating used</td>
            </td>
            <td className={styles.column}>
              <td>{data.heatingEnergy.toFixed(0)}</td>
              <td>GJ/year/ha</td>
            </td>
            <td className={styles.column}>
              <td>{data.heatingEnergyUseDryFruit.toFixed(0)}</td>
              <td>MJ/kg</td>
            </td>
            <td style={{ paddingRight: 10 }} className={styles.column}>
              <td>{data.heatingEnergyUseFreshFruit.toFixed(1)}</td>
              <td>MJ/kg</td>
            </td>
          </tr>
          <tr>
            <td className={styles.column1}>
              <td>2</td>
              <td>Electrical energy used</td>
            </td>
            <td className={styles.column}>
              <td>{data.electricalEnergy.toFixed(0)}</td>
              <td>MWh/ha</td>
            </td>
            <td className={styles.column}>
              <td>{data.electricalEnergyUseDryFruit.toFixed(0)}</td>
              <td>MJ/kg</td>
            </td>
            <td style={{ paddingRight: 10 }} className={styles.column}>
              <td>{data.electricalEnergyUseFreshFruit.toFixed(1)}</td>
              <td>MJ/kg</td>
            </td>
          </tr>
          <tr>
            <td className={styles.column1}>
              <td>3</td>
              <td>Water used</td>
            </td>
            <td className={styles.column}>
              <td>{data.waterUsed.toFixed(0)}</td>
              <td>m3/ha</td>
            </td>
            <td className={styles.column}>
              <td>{data.waterUseDryFruit.toFixed(0)}</td>
              <td>Liters/kg</td>
            </td>
            <td style={{ paddingRight: 10 }} className={styles.column}>
              <td>{data.waterUseFreshFruit.toFixed(1)}</td>
              <td>Liters/kg</td>
            </td>
          </tr>
          <tr>
            <td className={styles.column1}>
              <td>4</td>
              <td>CO2 supplied</td>
            </td>
            <td className={styles.column}>
              <td>{data.cO2Used.toFixed(0)}</td>
              <td>tons/ha</td>
            </td>
            <td className={styles.column}>
              <td>{data.cO2DosingDryFruit.toFixed(3)}</td>
              <td>kg/kg</td>
            </td>
            <td style={{ paddingRight: 10 }} className={styles.column}>
              <td>{data.cO2DosingFreshFruit.toFixed(4)}</td>
              <td>kg/kg</td>
            </td>
          </tr>
          <tr>
            <td className={styles.column1}>
              <td>5</td>
              <td>Dry fruit production</td>
            </td>
            <td className={styles.column}>
              <td>{data.dryMatter.toFixed(2)}</td>
              <td>kg/m2</td>
            </td>
            <td className={styles.column}></td>
            <td className={styles.column}></td>
          </tr>
          <tr>
            <td className={styles.column1}>
              <td>6</td>
              <td>CO2 uptake (or photosynthesis) </td>
            </td>
            <td className={styles.column}>
              <td>{data.photosynthesis.toFixed(2)}</td>
              <td>kg/m2</td>
            </td>
            <td className={styles.column}></td>
            <td className={styles.column}></td>
          </tr>
          <tr>
            <td className={styles.column1}>
              <td>7</td>
              <td>CO2 emissions</td>
            </td>
            <td className={styles.column}>
              <td>{data.cO2Emission.toFixed(0)}</td>
              <td>tons/ha</td>
            </td>
            <td className={styles.column}>
              <td>{data.cO2EmissionsDryFruit.toFixed(1)}</td>
              <td>kg/kg</td>
            </td>
            <td style={{ paddingRight: 10 }} className={styles.column}>
              <td>{data.cO2EmissionsFreshFruit.toFixed(2)}</td>
              <td>kg/kg</td>
            </td>
          </tr>
        </tbody>
      </table>
      <Row>
        <Col span={12}>
          <div>
            <div style={{ height: 350, textAlign: 'center' }}>
              <img src={chart} />
            </div>
            <div style={{ fontSize: 20, textAlign: 'center', marginTop: -13 }}>
              <b>SIOM photosynthetic Climate Index</b>
              {/* eslint-disable-next-line react/no-unescaped-entities*/}
              <p style={{ fontSize: 19, paddingRight: 20, paddingLeft: 20, marginBottom: 3, marginTop: 5 }}>
                Monthly Overview in Percentage over 12 Months
              </p>
              <p style={{ fontSize: 14, paddingRight: 20, paddingLeft: 20, margin: 0 }}>
                Note: The index represents the percentage of maximum dry matter production, factoring in temperature, humidity, and CO2
                inhibitory effects on photosynthesis and crop growth.
              </p>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div style={{ backgroundColor: '#64BD4B', color: '#fff', padding: 10 }}>
            <b>Disclaimer: SIOM Calculation Results</b>
            <p>
              The results obtained through the use of SIOM (or any specific version, if applicable) are provided solely as indications and
              should be interpreted as such. These results are intended to offer a general overview and are not exhaustive in detailing
              specific parameters or strategies. For a more accurate and tailored analysis, it is recommended to utilize SIOM Advance.
            </p>
            <p>
              SIOM Advance allows for a more in-depth exploration, enabling users to define additional details and customize strategies
              based on the specific location and intended use/purpose of the greenhouse. The advanced features of SIOM Advance provide a
              comprehensive platform for a nuanced and precise assessment.
            </p>
            <p>
              Please be aware that the results generated by the basic version of SIOM may not capture the intricacies required for highly
              specific or specialized applications. Users are encouraged to employ SIOM Advance to ensure a more thorough understanding and
              optimization of their greenhouse-related calculations.
            </p>
            <p>
              It is important to note that the indications provided by SIOM are not exhaustive, and the accuracy of results may vary based
              on the completeness of input data and the complexity of the greenhouse scenario.
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SiomOutput;

import { FC, useEffect, useState } from 'react';

import { Collapse } from 'antd';

import styles from './collapse.module.scss';

interface CollapseItem {
  key: string;
  label: string;
  children: JSX.Element;
}

interface CollapseProps {
  collapseItems: CollapseItem[];
  toggleClose: boolean;
}

export const KISCollapse: FC<CollapseProps> = ({ collapseItems, toggleClose }) => {
  const [activeRows, setActiveRows] = useState<string[]>(['geometryAndConstruction.geometryGenericDesignData']);
  useEffect(() => {
    if (!toggleClose) {
      setActiveRows([]);
    } else {
      collapseItems.map(item => {
        setActiveRows(prevState => [...prevState, item.key]);
      });
    }
  }, [toggleClose]);

  const handleCollapse = (key: string | string[]) => {
    setActiveRows(key as string[]);
  };

  return (
    <div className={styles.collapse}>
      <Collapse defaultActiveKey={activeRows} activeKey={activeRows} onChange={handleCollapse} className={styles.content}>
        {collapseItems.map(item => (
          <Collapse.Panel header={item.label} key={item.key}>
            {item.children}
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};

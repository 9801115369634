import { FC } from 'react';
import { BrowserRouter, Navigate, useRoutes } from 'react-router-dom';

import { Login } from '@/modules/auth';
import Home from '@/modules/home';
import { ProjectWrapper } from '@/modules/project/pages';

import { BaseLayout } from '@/shared/components';

export const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

const Routes = () => {
  // .... is auth function
  const routes = useRoutes([
    {
      element: <BaseLayout />,
      children: [
        { element: <Home />, path: '/' },
        {
          path: '/project/:projectId',
          element: <ProjectWrapper projectType="edit" />,
        },
        {
          path: '/new-project',
          element: <ProjectWrapper projectType="new" />,
        },
        {
          path: '/login',
          element: <Login />,
        },
        {
          path: '/*',
          element: <Navigate to="/" />,
        },
      ],
    },
  ]);
  return routes;
};

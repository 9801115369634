export const darkTheme = {
  colorBgContainer: '#141414',
  colorBgElevated: '#1f1f1f',
  colorBgLayout: '#000',
  colorBgSpotlight: '#424242',
  colorBgMask: 'rgba(0, 0, 0, 0.45)',
  colorText: 'rgba(255,255,255, 0.88)',
  colorTextSecondary: 'rgba(255,255,255, 0.65)',
  colorTextTertiary: 'rgba(255,255,255, 0.45)',
  colorTextQuaternary: 'rgba(255,255,255, 0.25)',
  colorBorder: '#424242',
  colorBorderSecondary: '#303030',
  colorBgBase: '#000',
  colorPrimaryBg: '#8b8b8b',
};
export const lightheme = {
  colorBgContainer: '#fff',
  colorBgElevated: '#fff',
  colorBgLayout: 'transparent',
  colorBgSpotlight: 'rgba(0, 0, 0, 0.5)',
  colorBgMask: 'rgba(0, 0, 0, 0.5)',
  colorText: 'rgba(0, 0, 0, 0.88)',
  colorTextSecondary: 'rgba(0, 0, 0, 0.65)',
  colorTextTertiary: 'rgba(0, 0, 0, 0.45)',
  colorTextQuaternary: 'rgba(0, 0, 0, 0.25)',
  colorBorder: '#d9d9d9',
  colorBgBase: '#fff',
  colorBorderSecondary: '#f0f0f0',
  colorPrimaryBg: '#ececec',
};

export type Theme = {
  colorBgContainer: string;
  colorBgElevated: string;
  colorBgLayout: string;
  colorBgSpotlight: string;
  colorBgMask: string;
  colorText: string;
  colorTextSecondary: string;
  colorTextTertiary: string;
  colorTextQuaternary: string;
  colorBorder: string;
  colorBorderSecondary: string;
};

import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, Row, Tooltip, notification } from 'antd';

import { downloadReleaseDatasetForGlazenStad, getReleaseDatasetForGlazenStad } from '@/modules/project/model';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faFileExport } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { GlazenStadType } from '../../data-exchange/models/data-exchange-model';
import styles from './form.module.scss';

export const GlazenStadForm: FC<{ disableFields: boolean }> = ({ disableFields }) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const [data, setData] = useState<GlazenStadType>();
  const [loading, setLoading] = useState(false);
  const abortController = useMemo(() => new AbortController(), []);

  const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  };

  useEffect(() => {
    getReleaseDatasetForGlazenStad(projectId || '', abortController.signal)
      .then(data => {
        setData(data);
      })
      .catch(err => {
        if (!abortController.signal.aborted) notification.error({ message: err.request.response });
      });
    return () => {
      abortController.abort();
    };
  }, [abortController, projectId]);

  const download = () => {
    setLoading(true);
    downloadReleaseDatasetForGlazenStad(projectId || '', abortController.signal)
      .then(res => {
        const a = document.createElement('a'); //Create <a>
        a.href = `data:application/octet-stream;base64,${res?.file}`; //Image Base64 Goes here
        a.download = res?.name || 'file.ckd'; //File name Here
        a.click(); //Downloaded file
        setLoading(false);
      })
      .catch(err => {
        if (!abortController.signal.aborted) notification.error({ message: err.request.response });
        setLoading(false);
      });
  };
  return (
    <div className={styles.form}>
      <div {...formItemLayout} className={styles.formCard}>
        <Row>
          <Col span={20} className={styles.formItemWrap}>
            <p>{t('projects.glazenStad.castaData.text')}</p>
          </Col>
          <Col span={2} className={styles.tooltip}>
            {t('projects.siom.template.tooltip').length !== 0 && (
              <Tooltip title={t('projects.siom.template.tooltip')} placement="right" overlayClassName={styles.tooltip}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            )}
          </Col>
        </Row>
        <Row></Row>
        <Row>
          <Col span={6} className={styles.formItemWrap}>
            {data?.date ? new Date(data?.date || '').toLocaleString('nl') : 'no file'}
          </Col>
          <Col span={12} className={styles.formItemWrap}>
            {data?.filename}
          </Col>
          <Col span={6} className={styles.formItemWrap}>
            <Button
              loading={loading}
              disabled={disableFields}
              onClick={download}
              icon={
                <FontAwesomeIcon
                  style={{
                    marginRight: '.5rem',
                  }}
                  icon={faFileExport}
                />
              }
              type="primary"
              style={{ float: 'inline-end' }}>
              {t('projects.dataExchange.exportDataset')}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

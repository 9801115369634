import { FC } from 'react';

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

interface ProjectCardTitleProps {
  className?: string;
  icon: FontAwesomeIconProps['icon'];
  title: string;
}

export const ProjectCardTitle: FC<ProjectCardTitleProps> = ({ className, icon, title }) => {
  return (
    <h2 className={className}>
      <FontAwesomeIcon icon={icon} />
      {title}
    </h2>
  );
};

import { MapContainer, Marker, TileLayer } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';

import icon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import shadow from 'leaflet/dist/images/marker-shadow.png';

import L from 'leaflet';

import { Form } from 'antd';
import styles from './map.module.scss';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: icon2x,
  iconUrl: markerIcon,
  shadowUrl: shadow,
});

export const KISMap = (): JSX.Element => {
  return (
    <div>
      <Form.Item
        noStyle
        shouldUpdate={(prev, cur) =>
          prev?.project?.generalInformation?.longitude !== cur?.project?.generalInformation?.longitude ||
          prev?.project?.generalInformation?.latitude !== cur?.project?.generalInformation?.latitude
        }>
        {({ getFieldValue }) => {
          const latitude = getFieldValue(['project', 'generalInformation', 'latitude']);
          const longitude = getFieldValue(['project', 'generalInformation', 'longitude']);
          if (latitude && longitude)
            return (
              <MapContainer
                key={[latitude, longitude].toString()}
                zoom={13}
                scrollWheelZoom={false}
                center={[latitude, longitude]}
                className={styles.map}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[latitude, longitude]} />
              </MapContainer>
            );
        }}
      </Form.Item>
    </div>
  );
};

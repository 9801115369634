import { Checkbox, Input, InputNumber, Radio, Select, Space, Switch } from 'antd';
import countryList from 'country-list';

import { FieldType, MeasurementEnum } from '@/modules/project/types/project.types';
import { CustomDatePicker } from '@/shared/components/custom-datepicker/CustomDatepicker';

export const filterOption = (input: string, option: { label: string; value: string }): boolean =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

export const getAddon = (addon?: string): JSX.Element => {
  switch (addon) {
    case MeasurementEnum.M2:
      return <span>m²</span>;
    case MeasurementEnum.NM2:
      return <span>N/m²</span>;
    default:
      return <span>{addon}</span>;
  }
};

const getStep = (field: FieldType) => {
  let step = '';

  if (field.step) {
    step = field.step.toString();
    if (step.indexOf('e') !== -1) {
      // Convert step value from exponent notation to decimal notation
      const exponent = step.split('e-')[1];
      const exponentValue = parseInt(exponent, 10);
      const zeros = '0'.repeat(exponentValue - 1);
      const decimalValue = step.replace('e-' + exponent, zeros).replace(exponent, '');
      const decimalStep = '0.' + decimalValue.split('1')[1] + '1';

      // Set step value for input field
      step = decimalStep;
    }
  }
  return step;
};

export const renderField = (field: FieldType, disableField: boolean, placeholder?: string): JSX.Element => {
  const disabled = disableField ? disableField : field.disabled;

  switch (field.type) {
    case 'text':
      return <Input disabled={disabled} placeholder={placeholder?.toString()} />;

    case 'number': {
      const step = getStep(field);
      return (
        <InputNumber
          disabled={disabled}
          min={field.min}
          max={field.max}
          step={step}
          decimalSeparator={field.dotSeparator || ','}
          addonAfter={field.addonAfter && getAddon(field.addonAfter)}
          style={{ width: '100%' }}
          precision={step?.split('.')[1]?.length}
          placeholder={placeholder?.toString()}
        />
      );
    }
    case 'date':
      return <CustomDatePicker format="DD-MM-YYYY" style={{ width: '100%' }} disabled={disabled} placeholder={placeholder?.toString()} />;

    case 'yearlessdate':
      return <CustomDatePicker format="DD-MM" style={{ width: '100%' }} disabled={disabled} placeholder={placeholder?.toString()} />;

    case 'textarea':
      return (
        <Input.TextArea
          rows={3}
          autoSize={{ minRows: 3, maxRows: 5 }}
          showCount
          maxLength={100}
          disabled={disabled}
          placeholder={placeholder?.toString()}
        />
      );

    case 'select-country':
      return (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => filterOption(input, option as any)}
          disabled={disabled}
          options={countryList.getData().map(country => ({ key: country.code, label: country.name, value: country.code }))}
          placeholder={placeholder?.toString()}
        />
      );

    case 'select':
      return (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => filterOption(input, option as any)}
          disabled={disabled}
          options={field.selectOptions}
          placeholder={placeholder?.toString()}
        />
      );

    case 'checkbox':
      if (field.selectOptions?.length) {
        return (
          <Checkbox.Group disabled={disabled}>
            <Space direction="vertical">
              {field.selectOptions.map(option => (
                <Checkbox key={option.value} value={option.value}>
                  {option.label}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        );
      } else {
        return <Switch style={{ verticalAlign: 'baseline' }} disabled={disabled} />;
      }

    case 'radio':
      return (
        <Radio.Group optionType="button" buttonStyle="solid" disabled={disabled}>
          {field.selectOptions?.map(option => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
      );

    default:
      return <Input disabled={field.disabled} />;
  }
};

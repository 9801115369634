import {
  getDataExchangeExportById,
  getDataExchangeWithProjectId,
  postDataExchangeFile,
  releaseDatasetForGlazenStad,
} from '@/modules/project/model';
import { faFileExport, faFileImport, faGears, faLeftRight, faListCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Card, Form, Modal, Space, Switch, Table, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ProjectCardTitle } from '../shared';

import { ProjectStatusEnum, ResultEnum, RightsEnum } from '@/modules/project/types/project.types';
import ImportModal from './components/import-modal';
import ProcessFileModal from './components/process-file-modal';
import VerifyDataModal from './components/verify-data-modal';
import styles from './dataExchange.module.scss';
import { DataExchangeTableType, ExportDataType } from './models/data-exchange-model';

export const DataExchange: FC<{ auth: number }> = ({ auth }) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const [modal, setModal] = useState<{ title: string; visible: boolean; content: ReactNode; width: number; hideButtons?: boolean }>({
    title: '',
    visible: false,
    content: null,
    width: 600,
    hideButtons: false,
  });
  const [dataSource, setDataSource] = useState<DataExchangeTableType[]>([]);
  const [loading, setLoading] = useState(false);

  const abortController = new AbortController();

  const columns: ColumnsType<DataExchangeTableType> = [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: date => {
        return date ? new Date(date).toLocaleString('nl') : '';
      },
    },
    {
      title: t('filename'),
      dataIndex: 'filename',
      key: 'filename',
    },
    {
      title: t('result'),
      dataIndex: 'result',
      key: 'result',
      render: result => {
        switch (result) {
          case ResultEnum.READY_TO_PROCESS:
            return (
              <>
                <Badge color="blue" /> ready to process
              </>
            );
            break;
          case ResultEnum.PROCESSED:
            return (
              <>
                <Badge color="green" /> file processed
              </>
            );
          case ResultEnum.FAILED:
            return (
              <>
                <Badge color="red" /> process failed
              </>
            );

          default:
            break;
        }
      },
    },
    {
      title: t('uploadedBy'),
      dataIndex: 'uploadedBy',
      key: 'uploadedBy',
    },
    {
      title: t('info'),
      dataIndex: 'info',
      key: 'info',
    },
    {
      title: t('isReleasedForGlazenStad'),
      align: 'right',
      dataIndex: 'isReleasedForGlazenStad',
      key: 'isReleasedForGlazenStad',
      render: (_, record) => {
        if (record?.showIsReleaseableGlazenStad) {
          return (
            <Switch
              style={{ float: 'right' }}
              onChange={() => {
                releaseDatasetForGlazenStad(projectId || '', abortController.signal)
                  .then(() => fetchData())
                  .catch(err => {
                    if (!abortController.signal.aborted) notification.error({ message: err.request.response });
                  });
              }}
              checked={record.isReleasedForGlazenStad}
            />
          );
        }
      },
    },
    {
      title: t('actions.title'),
      key: 'actions',
      align: 'right',
      render: (_, record) => {
        if (auth === RightsEnum.OWNER)
          return (
            <Space size="middle">
              {(record.result === ProjectStatusEnum.CONCEPT || record.result === ProjectStatusEnum.PROCESSED) && (
                <Button type="default" disabled>
                  <FontAwesomeIcon icon={faGears} />
                  &nbsp;
                  {t('actions.processFile')}
                </Button>
              )}
              {record.result === ProjectStatusEnum.OFFER && (
                <>
                  <Button
                    type="default"
                    onClick={() =>
                      setModal({
                        title: t('actions.verifyData'),
                        visible: true,
                        width: 1000,
                        content: <VerifyDataModal id={record.id} />,
                        hideButtons: true,
                      })
                    }>
                    <FontAwesomeIcon icon={faListCheck} />
                    &nbsp;
                    {t('actions.verifyData')}
                  </Button>
                  <Button
                    type="default"
                    onClick={() =>
                      setModal({
                        title: t('actions.processFile'),
                        visible: true,
                        content: <ProcessFileModal id={record.id} />,
                        width: 600,
                      })
                    }>
                    <FontAwesomeIcon icon={faGears} />
                    &nbsp;
                    {t('actions.processFile')}
                  </Button>
                </>
              )}
            </Space>
          );
      },
    },
  ];
  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const fetchData = () => {
    if (!projectId) return;
    getDataExchangeWithProjectId(projectId, abortController.signal)
      .then(data => {
        setDataSource(data);
      })
      .catch(e => {
        if (!abortController.signal.aborted) console.error(e);
      });
  };

  useEffect(() => {
    fetchData();
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modalSave = async values => {
    if (!values.files) {
      fetchData();
      setModal({ title: '', content: null, visible: false, width: 600 });
    }
    const data = await toBase64(values.files.file.originFileObj);

    postDataExchangeFile(
      {
        file: (data as string).split(',')[1],
        information: values.information,
        filename: values.files.file.name,
        projectId: projectId || '',
      },
      abortController.signal
    )
      .then(() => {
        notification.success({ message: t('importedSuccessfully') });
        fetchData();
        setModal({ title: '', content: null, visible: false, width: 600 });
      })
      .catch(err => {
        if (!abortController.signal.aborted) notification.error({ message: err.request.response });
      });
  };
  const download = () => {
    setLoading(true);
    getDataExchangeExportById(projectId || '', abortController.signal)
      .then(res => {
        const a = document.createElement('a'); //Create <a>
        a.href = `data:application/octet-stream;base64,${res?.file}`; //Image Base64 Goes here
        a.download = res?.name || 'file.ckd'; //File name Here
        a.click(); //Downloaded file
        setLoading(false);
      })
      .catch(err => {
        if (!abortController.signal.aborted) notification.error({ message: err.request.response });
        setLoading(false);
      });
  };

  return (
    <Card
      className={styles.card}
      title={<ProjectCardTitle className={styles.title} icon={faLeftRight} title={t('projects.dataExchange.title')} />}
      style={{ width: '100%' }}
      extra={
        <Space>
          <Button
            disabled={auth !== RightsEnum.OWNER && auth !== RightsEnum.WRITE}
            type="primary"
            onClick={() =>
              setModal({
                title: t('projects.dataExchange.importDataset'),
                visible: true,
                content: <ImportModal />,
                width: 600,
              })
            }>
            <FontAwesomeIcon icon={faFileImport} style={{ marginRight: '.5rem' }} />
            {t('projects.dataExchange.importDataset')}
          </Button>

          <Button type="primary" loading={loading} onClick={download}>
            <FontAwesomeIcon icon={faFileExport} style={{ marginRight: '.5rem' }} />
            {t('projects.dataExchange.exportDataset')}
          </Button>
        </Space>
      }>
      <div>
        <Table columns={columns} dataSource={dataSource} pagination={false} />
      </div>
      <Modal
        title={modal.title}
        open={modal.visible}
        width={modal.width}
        destroyOnClose
        footer={modal.hideButtons ? false : undefined}
        onOk={() => form.submit()}
        onCancel={() => {
          setModal(modal => {
            return { ...modal, visible: false };
          });
        }}>
        <Form onFinish={modalSave} form={form}>
          {modal.content}
        </Form>
      </Modal>
    </Card>
  );
};

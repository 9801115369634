import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Divider, Row } from 'antd';

import { FormType, VerifyDataType } from '@/modules/project/types/project.types';
import styles from './form.module.scss';

interface VerifyFormProps {
  formStructure: FormType;
  verifyData: VerifyDataType;
}

export const VerifyForm: FC<VerifyFormProps> = ({ formStructure, verifyData }) => {
  const { t } = useTranslation();

  const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  };

  return (
    <div className={styles.form}>
      {verifyData[formStructure.sections[0].objectName].length === 0 ? (
        <>No changes</>
      ) : (
        <>
          <Row>
            <Col span={8}></Col>
            <Col style={{ textAlign: 'right' }} span={7}>
              <b>Current KIS value</b>
            </Col>
            <Col style={{ textAlign: 'right' }} span={7}>
              <b>New Casta value</b>
            </Col>
            <Col span={2}></Col>
          </Row>
          <Divider style={{ margin: 0 }} />
          {formStructure.sections.map(section => (
            <div {...formItemLayout} className={styles.formCard} key={section.title}>
              {section.fields.map(
                field =>
                  verifyData[field.name[1]].find(f => f.prop.toLowerCase() === field.name[2].toLowerCase()) && (
                    <>
                      <Row style={{ margin: '10px 0px' }} key={field.name.toString()}>
                        <Col span={8}>{t(field.label)}</Col>
                        <Col style={{ textAlign: 'right' }} span={7}>
                          {verifyData[field.name[1]].find(f => f.prop.toLowerCase() === field.name[2].toLowerCase())?.kisValue.toString()}
                        </Col>
                        <Col style={{ textAlign: 'right' }} span={7}>
                          {verifyData[field.name[1]].find(f => f.prop.toLowerCase() === field.name[2].toLowerCase())?.castaValue.toString()}
                        </Col>
                        <Col style={{ textAlign: 'right' }} span={2}>
                          {field.addonAfter}
                        </Col>
                      </Row>
                      <Divider style={{ margin: 0 }} />
                    </>
                  )
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

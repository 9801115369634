import { FC } from 'react';
import { Col, Dropdown, MenuProps, Space, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUser } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import logo from '@/assets/logo.svg';

import styles from './header.module.scss';
import { useAtomValue } from 'jotai';
import { atoms } from '@/shared/atoms';

interface HeaderProps {
  userName: string;
  userCompany: string;
}

const { Text } = Typography;

export const KISHeader: FC<HeaderProps> = ({ userName, userCompany }) => {
  const { t } = useTranslation();
  const keycloak = useAtomValue(atoms.auth.keycloakInstance);
  const handleLogout = () => {
    keycloak.logout();
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: t('actions.logout'),
      icon: <FontAwesomeIcon icon={faSignOutAlt} />,
      onClick: () => handleLogout(),
    },
  ];

  return (
    <Header className={styles.header}>
      <Col span={16} className={styles.navSection}>
        <Col>
          <Link to="/" className={styles.logoWrapper}>
            <img className={styles.logo} src={logo} />
          </Link>
        </Col>
        <Col>
          <h4 className={styles.navTitle}>{t('projects.projects')}</h4>
        </Col>
      </Col>
      <Col span={8} className={`${styles.navSection} ${styles.right}`}>
        <Col>
          <Dropdown menu={{ items }}>
            <a onClick={e => e.preventDefault()} className={styles.person}>
              <Space align="center">
                <FontAwesomeIcon icon={faUser} size={'xl'} className={styles.icon} />
                <Text>{userName}</Text>
              </Space>
            </a>
          </Dropdown>
        </Col>
        <Col>
          <Text disabled>{userCompany}</Text>
        </Col>
      </Col>
    </Header>
  );
};

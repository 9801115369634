import { faHouse } from '@fortawesome/pro-solid-svg-icons';
import { FormType, MeasurementEnum } from '../types/project.types';

export const generalSiomFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: '',
      icon: faHouse,
      span: 24,
      fields: [
        {
          label: 'projects.generalInformation.project.contractor.title',
          tooltip: 'projects.generalInformation.project.contractor.tooltip',
          name: ['project', 'contractor', 'name'],
          type: 'text',
          required: true,
          disabled: true,
          requiredMessage: 'projects.generalInformation.project.projectName.requiredMessage',
        },
        {
          label: 'projects.generalInformation.project.client.title',
          tooltip: 'projects.generalInformation.project.client.tooltip',
          name: ['project', 'generalInformation', 'client'],
          type: 'text',
          required: true,
          disabled: true,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
        {
          label: 'projects.siom.generalSiom.projectName.title',
          tooltip: 'projects.siom.generalSiom.projectName.tooltip',
          name: ['siomProject', 'general', 'projectName'],
          type: 'text',
          required: true,
          disabled: false,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
        {
          label: 'projects.generalInformation.location.locationPlace.title',
          tooltip: 'projects.generalInformation.location.locationPlace.tooltip',
          name: ['project', 'generalInformation', 'city'],
          type: 'text',
          required: true,
          disabled: true,
          requiredMessage: 'projects.generalInformation.project.constructionStartDate.requiredMessage',
        },
      ],
    },
    {
      title: '',
      objectName: '',
      icon: faHouse,
      span: 24,
      fields: [
        {
          label: 'projects.siom.generalSiom.description.title',
          tooltip: 'projects.siom.generalSiom.description.tooltip',
          name: ['siomProject', 'general', 'description'],
          type: 'text',
          required: true,
          disabled: false,
        },
        {
          label: 'projects.generalInformation.location.latitude.title',
          tooltip: 'projects.generalInformation.location.latitude.tooltip',
          name: ['project', 'generalInformation', 'latitude'],
          type: 'number',
          min: -90,
          max: 90,
          step: 0.00000001,
          dotSeparator: '.',
          required: true,
          disabled: true,
          requiredMessage: 'projects.generalInformation.location.latitude.requiredMessage',
          defaultValue: 52.0842635,
        },
        {
          label: 'projects.generalInformation.location.longitude.title',
          tooltip: 'projects.generalInformation.location.longitude.tooltip',
          name: ['project', 'generalInformation', 'longitude'],
          type: 'number',
          min: -180,
          max: 180,
          step: 0.00000001,
          dotSeparator: '.',
          required: true,
          disabled: true,
          requiredMessage: 'projects.generalInformation.location.longitude.requiredMessage',
          defaultValue: 5.0824915,
        },
      ],
    },
  ],
};

export const constructionFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: '',
      icon: faHouse,
      span: 24,
      fields: [
        {
          label: 'projects.siom.construction.constructionType.title',
          tooltip: 'projects.siom.construction.constructionType.tooltip',
          name: ['project', 'generalInformation', 'type'],
          type: 'select',
          required: true,
          disabled: true,
          requiredMessage: 'projects.generalInformation.greenhouse.greenhouseType.requiredMessage',
          defaultValue: 'Venlo',
          selectOptions: [
            {
              label: 'Venlo',
              value: 'Venlo',
              key: 'Venlo',
            },
          ],
        },
        {
          label: 'projects.siom.construction.greenhouseArea.title',
          tooltip: 'projects.siom.construction.greenhouseArea.tooltip',
          name: ['siomProject', 'construction', 'greenhouseArea'],
          type: 'number',
          required: true,
          disabled: false,
          step: 1,
          min: 1,
          max: 2,
          dotSeparator: '.',
          addonAfter: MeasurementEnum.HA,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
        {
          label: 'projects.geometryAndConstruction.dimensionsOFInnerField.innerField.columnHeight.title',
          tooltip: 'projects.geometryAndConstruction.dimensionsOFInnerField.innerField.columnHeight.tooltip',
          name: ['geometryAndConstructionProject', 'innerField', 'columnHeight'],
          addonAfter: MeasurementEnum.M,
          type: 'number',
          required: true,
          disabled: true,
          step: 0.001,
          min: 2,
          max: 15,
        },
        {
          label: 'projects.geometryAndConstruction.dimensionsOFInnerField.innerField.foundationHeight.title',
          tooltip: 'projects.geometryAndConstruction.dimensionsOFInnerField.innerField.foundationHeight.tooltip',
          name: ['geometryAndConstructionProject', 'innerField', 'foundationHeight'],
          addonAfter: MeasurementEnum.MM,
          type: 'number',
          required: true,
          disabled: true,
          step: 1,
          min: 0,
          max: 2200,
        },
      ],
    },
    {
      title: '',
      objectName: '',
      icon: faHouse,
      span: 24,
      fields: [
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.section.sectionLength.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.section.sectionLength.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'sectionLength'],
          type: 'number',
          addonAfter: MeasurementEnum.M,
          required: true,
          disabled: true,
          step: 0.001,
          min: 2,
          max: 8,
        },
        {
          label: 'projects.siom.construction.roofSpanWidth.title',
          tooltip: 'projects.siom.construction.roofSpanWidth.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'width'],
          type: 'number',
          addonAfter: MeasurementEnum.M,
          required: true,
          disabled: true,
          step: 0.001,
          min: 3.2,
          max: 8,
        },
        {
          label: 'projects.geometryAndConstruction.geometryGenericDesignData.roof.roofAngle.title',
          tooltip: 'projects.geometryAndConstruction.geometryGenericDesignData.roof.roofAngle.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'roofAngle'],
          addonAfter: MeasurementEnum.DEGREE,
          type: 'number',
          required: true,
          disabled: true,
          step: 0.1,
          min: 19,
          max: 30,
        },
        {
          label: 'projects.siom.construction.greenhouseOrientation.title',
          tooltip: 'projects.siom.construction.greenhouseOrientation.tooltip',
          name: ['project', 'generalInformation', 'orientation'],
          type: 'number',
          addonAfter: MeasurementEnum.DEGREE,
          required: true,
          disabled: true,
          requiredMessage: 'projects.generalInformation.greenhouse.orientation.requiredMessage',
          min: -90,
          max: 90,
          step: 0.001,
        },
      ],
    },
  ],
};

export const semiClosedSystemFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: '',
      icon: faHouse,
      span: 24,
      fields: [
        {
          label: 'projects.siom.semiClosedSystem.title',
          tooltip: 'projects.siom.semiClosedSystem.tooltip',
          name: ['siomProject', 'semiClosedSystem', 'isSemiClosedSystem'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.siom.semiClosedSystem.ventilationCapacity.title',
          tooltip: 'projects.siom.semiClosedSystem.ventilationCapacity.tooltip',
          name: ['siomProject', 'semiClosedSystem', 'ventilationCapacity'],
          type: 'select',
          required: true,
          disabled: false,
          selectOptions: [
            { label: 'Low', value: 'low', key: 'low' },
            { label: 'Normal', value: 'normal', key: 'normal' },
            { label: 'High', value: 'high', key: 'high' },
            { label: 'Custom', value: 'custom', key: 'custom' },
          ],
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
          hideIfBoolFalse: ['siomProject', 'semiClosedSystem', 'isSemiClosedSystem'],
        },
        {
          label: 'projects.siom.semiClosedSystem.minVentilationRate.title',
          tooltip: 'projects.siom.semiClosedSystem.minVentilationRate.tooltip',
          name: ['siomProject', 'semiClosedSystem', 'minimumVentilationRate'],
          type: 'number',
          required: true,
          disabled: false,
          min: 30,
          max: 75,
          addonAfter: MeasurementEnum.M3HM2,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
          hideIfBoolFalse: ['siomProject', 'semiClosedSystem', 'isSemiClosedSystem'],
        },
        {
          label: 'projects.siom.semiClosedSystem.maxVentilationRate.title',
          tooltip: 'projects.siom.semiClosedSystem.maxVentilationRate.tooltip',
          name: ['siomProject', 'semiClosedSystem', 'maximumVentilationRate'],
          type: 'number',
          required: true,
          disabled: false,
          min: 60,
          max: 150,
          addonAfter: MeasurementEnum.M3HM2,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
          hideIfBoolFalse: ['siomProject', 'semiClosedSystem', 'isSemiClosedSystem'],
        },
      ],
    },
  ],
};

export const cropFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: '',
      icon: faHouse,
      span: 24,
      fields: [
        {
          label: 'projects.siom.crop.cropProduced.title',
          tooltip: 'projects.siom.crop.cropProduced.tooltip',
          name: ['siomProject', 'crop', 'cropProduced'],
          type: 'select',
          required: true,
          disabled: false,
          selectOptions: [
            { label: 'Tomato', value: 'tomato', key: 'tomato' },
            { label: 'Cucumber', value: 'cucumber', key: 'cucumber' },
            { label: 'Sweet pepper', value: 'sweetPepper', key: 'sweetPepper' },
            { label: 'None', value: 'none', key: 'none' },
          ],
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
        {
          label: 'projects.siom.crop.cropPlantingDate.title',
          tooltip: 'projects.siom.crop.cropPlantingDate.tooltip',
          name: ['siomProject', 'crop', 'cropPlantingDate'],
          type: 'yearlessdate',
          required: true,
          disabled: false,
          requiredMessage: 'projects.generalInformation.project.constructionStartDate.requiredMessage',
          hideIfSelectIsValue: {
            name: ['siomProject', 'crop', 'cropProduced'],
            value: 'none',
          },
        },
        {
          label: 'projects.siom.crop.cropRemovalDate.title',
          tooltip: 'projects.siom.crop.cropRemovalDate.tooltip',
          name: ['siomProject', 'crop', 'cropRemovalDate'],
          type: 'yearlessdate',
          required: true,
          disabled: false,
          requiredMessage: 'projects.generalInformation.project.constructionStartDate.requiredMessage',
          hideIfSelectIsValue: {
            name: ['siomProject', 'crop', 'cropProduced'],
            value: 'none',
          },
        },
      ],
    },
  ],
};

export const heatingFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: '',
      icon: faHouse,
      span: 24,
      fields: [
        {
          label: 'projects.siom.heating.boiler.title',
          tooltip: 'projects.siom.heating.boiler.tooltip',
          name: ['siomProject', 'heating', 'boiler'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.siom.heating.boilerPower.title',
          tooltip: 'projects.siom.heating.boilerPower.tooltip',
          name: ['siomProject', 'heating', 'boilerPower'],
          type: 'number',
          step: 1,
          min: 50,
          max: 300,
          addonAfter: MeasurementEnum.WM2,
          required: true,
          disabled: false,
          hideIfBoolFalse: ['siomProject', 'heating', 'boiler'],
        },
        {
          label: 'projects.siom.heating.buffer.title',
          tooltip: 'projects.siom.heating.buffer.tooltip',
          name: ['siomProject', 'heating', 'buffer'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.siom.heating.bufferCapacity.title',
          tooltip: 'projects.siom.heating.bufferCapacity.tooltip',
          name: ['siomProject', 'heating', 'bufferCapacity'],
          type: 'number',
          step: 1,
          min: 100,
          max: 500,
          addonAfter: MeasurementEnum.M3HA,
          required: true,
          disabled: false,
          hideIfBoolFalse: ['siomProject', 'heating', 'buffer'],
        },
      ],
    },
  ],
};

export const ventilationFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: '',
      icon: faHouse,
      span: 24,
      fields: [
        {
          label: 'projects.siom.ventilation.ventilatingWindowsPerSection.title',
          tooltip: 'projects.siom.ventilation.ventilatingWindowsPerSection.tooltip',
          name: ['siomProject', 'ventilation', 'ventilatingWindowsPerSection'],
          type: 'number',
          step: 1,
          min: 1,
          max: 4,
          required: true,
          disabled: false,
        },
        {
          label: 'projects.siom.ventilation.depthVentilationWindow.title',
          tooltip: 'projects.siom.ventilation.depthVentilationWindow.tooltip',
          name: ['siomProject', 'ventilation', 'depthVentilationWindow'],
          type: 'number',
          step: 1,
          min: 800,
          max: 1500,
          addonAfter: MeasurementEnum.MM,
          required: true,
          disabled: false,
        },
        {
          label: 'projects.siom.ventilation.widthWindowPane.title',
          tooltip: 'projects.siom.ventilation.widthWindowPane.tooltip',
          name: ['geometryAndConstructionProject', 'geometryGenericDesign', 'roofGlass'],
          type: 'number',
          step: 1,
          min: 800,
          max: 1500,
          addonAfter: MeasurementEnum.MM,
          required: true,
          disabled: true,
        },
      ],
    },
  ],
};

export const screensFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: '',
      icon: faHouse,
      span: 24,
      fields: [
        {
          label: 'projects.siom.screens.energyScreen.title',
          tooltip: 'projects.siom.screens.energyScreen.tooltip',
          name: ['siomProject', 'screens', 'energyScreen'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.siom.screens.shadingScreen.title',
          tooltip: 'projects.siom.screens.shadingScreen.tooltip',
          name: ['siomProject', 'screens', 'shadingScreen'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.siom.screens.blackoutScreen.title',
          tooltip: 'projects.siom.screens.blackoutScreen.tooltip',
          name: ['siomProject', 'screens', 'blackoutScreen'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
        {
          label: 'projects.siom.screens.insectScreen.title',
          tooltip: 'projects.siom.screens.insectScreen.tooltip',
          name: ['siomProject', 'screens', 'insectScreen'],
          type: 'checkbox',
          required: false,
          disabled: false,
        },
      ],
    },
  ],
};

export const carbonDioxideFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: '',
      icon: faHouse,
      span: 24,
      fields: [
        {
          label: 'projects.siom.carbonDioxide.useCo2Fertilisation.title',
          tooltip: 'projects.siom.carbonDioxide.useCo2Fertilisation.tooltip',
          name: ['siomProject', 'carbonDioxide', 'useC02Fertilisation'],
          type: 'select',
          required: true,
          disabled: false,
          selectOptions: [
            { label: 'No', value: 'no', key: 'no' },
            { label: 'Pure CO2', value: 'pure', key: 'pure' },
            { label: 'C02 from flue gas', value: 'flueGas', key: 'flueGas' },
          ],
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
        {
          label: 'projects.siom.carbonDioxide.rateOfCo2Fertilisation.title',
          tooltip: 'projects.siom.carbonDioxide.rateOfCo2Fertilisation.tooltip',
          name: ['siomProject', 'carbonDioxide', 'rateC02Fertilisation'],
          type: 'number',
          step: 1,
          min: 60,
          max: 180,
          required: true,
          disabled: false,
          addonAfter: MeasurementEnum.KGHAH,
          requiredMessage: 'projects.generalInformation.project.constructionStartDate.requiredMessage',
          hideIfSelectIsValue: {
            name: ['siomProject', 'carbonDioxide', 'useC02Fertilisation'],
            value: 'no',
          },
        },
      ],
    },
  ],
};

export const coolingFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: '',
      icon: faHouse,
      span: 24,
      fields: [
        {
          label: 'projects.siom.cooling.padAndFan.title',
          tooltip: 'projects.siom.cooling.padAndFan.tooltip',
          name: ['siomProject', 'cooling', 'padAndFan'],
          type: 'checkbox',
          required: false,
          disabled: false,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
        {
          label: 'projects.siom.cooling.maxVentilationRatePadnFan.title',
          tooltip: 'projects.siom.cooling.maxVentilationRatePadnFan.tooltip',
          name: ['siomProject', 'cooling', 'maxVentilationRatePadAndFan'],
          type: 'number',
          step: 1,
          min: 110,
          max: 230,
          required: true,
          disabled: false,
          addonAfter: MeasurementEnum.M3M2H,
          hideIfBoolFalse: ['siomProject', 'cooling', 'padAndFan'],
          requiredMessage: 'projects.generalInformation.project.constructionStartDate.requiredMessage',
        },
        {
          label: 'projects.siom.cooling.fogging.title',
          tooltip: 'projects.siom.cooling.fogging.tooltip',
          name: ['siomProject', 'cooling', 'fogging'],
          type: 'checkbox',
          required: false,
          disabled: false,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
        {
          label: 'projects.siom.cooling.flowCapacityFogging.title',
          tooltip: 'projects.siom.cooling.flowCapacityFogging.tooltip',
          name: ['siomProject', 'cooling', 'flowCapacityFogging'],
          type: 'number',
          step: 0.0001,
          min: 150,
          max: 1500,
          required: true,
          disabled: false,
          addonAfter: MeasurementEnum.GRM2HR,
          hideIfBoolFalse: ['siomProject', 'cooling', 'fogging'],
          requiredMessage: 'projects.generalInformation.project.constructionStartDate.requiredMessage',
        },
        {
          label: 'projects.siom.cooling.activeCooling.title',
          tooltip: 'projects.siom.cooling.activeCooling.tooltip',
          name: ['siomProject', 'cooling', 'activeCooling'],
          type: 'checkbox',
          required: false,
          disabled: false,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
        {
          label: 'projects.siom.cooling.electricalCoolingPower.title',
          tooltip: 'projects.siom.cooling.electricalCoolingPower.tooltip',
          name: ['siomProject', 'cooling', 'electricalCoolingPower'],
          type: 'number',
          step: 1,
          min: 50,
          max: 250,
          required: true,
          disabled: false,
          addonAfter: MeasurementEnum.M3M2H,
          hideIfBoolFalse: ['siomProject', 'cooling', 'activeCooling'],
          requiredMessage: 'projects.generalInformation.project.constructionStartDate.requiredMessage',
        },
      ],
    },
  ],
};

export const artificialLightingFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: '',
      icon: faHouse,
      span: 24,
      fields: [
        {
          label: 'projects.siom.artificialLighting.topLightsHps.title',
          tooltip: 'projects.siom.artificialLighting.topLightsHps.tooltip',
          name: ['siomProject', 'artificialLighting', 'topLightsHPS'],
          type: 'checkbox',
          required: false,
          disabled: false,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
        {
          label: 'projects.siom.artificialLighting.topLightsHpsPower.title',
          tooltip: 'projects.siom.artificialLighting.topLightsHpsPower.tooltip',
          name: ['siomProject', 'artificialLighting', 'topLightsHPSPower'],
          type: 'number',
          step: 1,
          min: 40,
          max: 110,
          required: true,
          disabled: false,
          addonAfter: MeasurementEnum.WM2,
          hideIfBoolFalse: ['siomProject', 'artificialLighting', 'topLightsHPS'],
          requiredMessage: 'projects.generalInformation.project.constructionStartDate.requiredMessage',
        },
        {
          label: 'projects.siom.artificialLighting.topLightsLed.title',
          tooltip: 'projects.siom.artificialLighting.topLightsLed.tooltip',
          name: ['siomProject', 'artificialLighting', 'topLightsLED'],
          type: 'checkbox',
          required: false,
          disabled: false,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
        {
          label: 'projects.siom.artificialLighting.topLightsLedPower.title',
          tooltip: 'projects.siom.artificialLighting.topLightsLedPower.tooltip',
          name: ['siomProject', 'artificialLighting', 'topLightsLEDPower'],
          type: 'number',
          step: 1,
          min: 25,
          max: 70,
          required: true,
          disabled: false,
          addonAfter: MeasurementEnum.WM2,
          hideIfBoolFalse: ['siomProject', 'artificialLighting', 'topLightsLED'],
          requiredMessage: 'projects.generalInformation.project.constructionStartDate.requiredMessage',
        },
        {
          label: 'projects.siom.artificialLighting.interlightsLed.title',
          tooltip: 'projects.siom.artificialLighting.interlightsLed.tooltip',
          name: ['siomProject', 'artificialLighting', 'interlightsLED'],
          type: 'checkbox',
          required: false,
          disabled: false,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
        {
          label: 'projects.siom.artificialLighting.interlightsLedPower.title',
          tooltip: 'projects.siom.artificialLighting.interlightsLedPower.tooltip',
          name: ['siomProject', 'artificialLighting', 'interlightsLEDPower'],
          type: 'number',
          step: 1,
          min: 15,
          max: 45,
          required: true,
          disabled: false,
          addonAfter: MeasurementEnum.WM2,
          hideIfBoolFalse: ['siomProject', 'artificialLighting', 'interlightsLED'],
          requiredMessage: 'projects.generalInformation.project.constructionStartDate.requiredMessage',
        },
      ],
    },
  ],
};

export const waterManagementSystemFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: '',
      icon: faHouse,
      span: 24,
      fields: [
        {
          label: 'projects.siom.waterManagementSystem.undergroundWaterSource.title',
          tooltip: 'projects.siom.waterManagementSystem.undergroundWaterSource.tooltip',
          name: ['siomProject', 'waterManagementSystem', 'undergroundWaterSource'],
          type: 'checkbox',
          required: false,
          disabled: false,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
        {
          label: 'projects.siom.waterManagementSystem.undergroundWaterQuality.title',
          tooltip: 'projects.siom.waterManagementSystem.undergroundWaterQuality.tooltip',
          name: ['siomProject', 'waterManagementSystem', 'undergroundWaterQuality'],
          type: 'radio',
          selectOptions: [
            {
              label: '1',
              value: 1,
              key: 1,
            },
            {
              label: '2',
              value: 2,
              key: 2,
            },
          ],
          required: true,
          disabled: false,
          addonAfter: MeasurementEnum.WM2,
          hideIfBoolFalse: ['siomProject', 'waterManagementSystem', 'undergroundWaterSource'],
          requiredMessage: 'projects.generalInformation.project.constructionStartDate.requiredMessage',
        },
        {
          label: 'projects.siom.waterManagementSystem.desalinationByReverseOsmosis.title',
          tooltip: 'projects.siom.waterManagementSystem.desalinationByReverseOsmosis.tooltip',
          name: ['siomProject', 'waterManagementSystem', 'desalinationByReverseOsmosis'],
          type: 'checkbox',
          required: false,
          disabled: false,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
        {
          label: 'projects.siom.waterManagementSystem.waterDisinfectionSystem.title',
          tooltip: 'projects.siom.waterManagementSystem.waterDisinfectionSystem.tooltip',
          name: ['siomProject', 'waterManagementSystem', 'waterDisinfectionSystem'],
          type: 'checkbox',
          required: false,
          disabled: false,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
      ],
    },
  ],
};

export const photovoltaicFormStructure: FormType = {
  sections: [
    {
      title: '',
      objectName: '',
      icon: faHouse,
      span: 24,
      fields: [
        {
          label: 'projects.siom.photovoltaics.selectPvInstallation.title',
          tooltip: 'projects.siom.photovoltaics.selectPvInstallation.tooltip',
          name: ['siomProject', 'photovoltaics', 'pvInstallation'],
          type: 'select',
          selectOptions: [
            {
              label: 'None',
              value: 'None',
              key: 'None',
            },
            {
              label: 'PV next to greenhouse',
              value: 'PvNextToGreenhouse',
              key: 'PvNextToGreenhouse',
            },
          ],
          required: true,
          disabled: false,
          requiredMessage: 'projects.generalInformation.project.commissioner.requiredMessage',
        },
        {
          label: 'projects.siom.photovoltaics.totalAreaOfPvElements.title',
          tooltip: 'projects.siom.photovoltaics.totalAreaOfPvElements.tooltip',
          name: ['siomProject', 'photovoltaics', 'totalAreaPVElements'],
          type: 'number',
          step: 1,
          min: 500,
          max: 30000,
          required: true,
          disabled: false,
          addonAfter: MeasurementEnum.M2,
          requiredMessage: 'projects.generalInformation.project.constructionStartDate.requiredMessage',
          hideIfSelectIsValue: { name: ['siomProject', 'photovoltaics', 'pvInstallation'], value: 'None' },
        },
      ],
    },
  ],
};

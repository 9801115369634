import { calculateSiom } from '@/modules/project/model';
import { SiomResponse } from '@/modules/project/types/project.types';
import { getRandomInt } from '@/shared/utilities/getRandomInt';
import { Progress } from 'antd';
import { t } from 'i18next';
import { FC, useEffect, useState } from 'react';
import SiomOutput from './siom-output';

interface CalculateSiomProps {
  projectId: string;
}
export const CalculateSiom: FC<CalculateSiomProps> = ({ projectId }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [status, setStatus] = useState<'active' | 'exception' | undefined>('active');
  const [isCalculating, setCalculating] = useState(true);
  const [percent, setPercent] = useState<number>(0);
  const [data, setData] = useState<SiomResponse>();

  useEffect(() => {
    const abortController = new AbortController();
    let interval = getRandomInt(800, 3000);
    setInterval(function () {
      if (isCalculating) {
        setPercent(percent => {
          const newPercent = percent + 1;
          if (percent <= 95) return newPercent;
          else return percent;
        });
      }
      interval = getRandomInt(800, 2500);
    }, interval);

    calculateSiom(projectId || '', abortController.signal)
      .then(response => {
        setStatus(undefined);
        setPercent(100);
        setData(response);
        setTimeout(() => {
          setCalculating(false);
        }, 1000);
      })
      .catch(err => {
        setStatus('exception');
        setPercent(100);
        setErrorMessage(err?.response?.data || err?.message);
      });
    return () => {
      abortController.abort();
    };
  }, [isCalculating, projectId]);

  return (
    <>
      {isCalculating && (
        <>
          {percent < 100 && t('calcSiomPleaseWait')}
          <Progress status={status} percent={percent} />
        </>
      )}
      {data && <SiomOutput data={data} />}
      {errorMessage.split('- The').map(str => (
        <p key={str}>{str}</p>
      ))}
    </>
  );
};

import { SiomResponse } from '@/modules/project/types/project.types';

export const getColorByValue = (value: number): string => {
  if (value === 0) return '#fff';
  if (value < 25) return '#A50026';
  if (value >= 25 && value < 30) return '#B40F26';
  if (value >= 30 && value < 35) return '#C62026';
  if (value >= 35 && value < 40) return '#D62F26';
  if (value >= 40 && value < 45) return '#E04430';
  if (value >= 45 && value < 50) return '#E95739';
  if (value >= 50 && value < 55) return '#F46D43';
  if (value >= 55 && value < 60) return '#F6814C';
  if (value >= 60 && value < 65) return '#FA9856';
  if (value >= 65 && value < 70) return '#FCAC60';
  if (value >= 70 && value < 75) return '#FDBE70';
  if (value >= 75 && value < 80) return '#FDCE7F';
  if (value >= 80 && value < 85) return '#FEE090';
  if (value >= 85 && value < 90) return '#FEE99E';
  if (value >= 90 && value < 95) return '#FEF4AF';
  if (value >= 95 && value < 100) return '#FEFEC0';
  if (value >= 100 && value < 105) return '#F4FBD2';
  if (value >= 105 && value < 110) return '#E9F6E6';
  if (value >= 110 && value < 115) return '#E0F3F7';
  if (value >= 115 && value < 120) return '#CDE9F2';
  if (value >= 120 && value < 125) return '#BCE1EE';
  if (value >= 125 && value < 130) return '#A9D8E8';
  if (value >= 130 && value < 135) return '#98CAE1';
  if (value >= 135 && value < 140) return '#85BAD8';
  if (value >= 140 && value < 145) return '#74ADD1';
  if (value >= 145 && value < 150) return '#6399C6';
  if (value >= 150 && value < 155) return '#5487BD';
  if (value >= 155 && value < 160) return '#4473B3';
  if (value >= 160 && value < 165) return '#3E60A9';
  if (value >= 165) return '#37499E';
  return '#fff';
};
export const getBackgroundColors = (climates: SiomResponse['climateIndexPerMonth']): string[] => {
  return [
    getColorByValue(climates['jan']) || '',
    getColorByValue(climates['feb']) || '',
    getColorByValue(climates['mar']) || '',
    getColorByValue(climates['apr']) || '',
    getColorByValue(climates['may']) || '',
    getColorByValue(climates['jun']) || '',
    getColorByValue(climates['jul']) || '',
    getColorByValue(climates['aug']) || '',
    getColorByValue(climates['sep']) || '',
    getColorByValue(climates['oct']) || '',
    getColorByValue(climates['nov']) || '',
    getColorByValue(climates['dec']) || '',
  ];
};

import { FC, Suspense, useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import nlNL from 'antd/es/locale/nl_NL';
import { useAtomValue } from 'jotai';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { atoms } from '@/shared/atoms';
import { ErrorBoundary } from '@/shared/components';
import { darkTheme, lightheme, Theme } from '@/shared/model/theme';

import { InitWrapper } from './InitWrapper';
import { AppRouter } from './router';
import { AuthWrapper } from '@/modules/auth';

const queryClient = new QueryClient();
export const App: FC = () => {
  const theme = useAtomValue(atoms.theme);
  const [themeConfig, setThemeConfig] = useState<Theme>();

  useEffect(() => {
    setThemeConfig(theme.isDarktheme ? darkTheme : lightheme);
  }, [theme.isDarktheme, theme]);

  return (
    <Suspense fallback={<p>loading</p>}>
      <QueryClientProvider client={queryClient}>
        <AuthWrapper>
          <InitWrapper>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: theme.primaryColor,
                  borderRadius: theme.borderRadius,
                  ...themeConfig,
                },
                components: {
                  Layout: {
                    colorBgHeader: theme.white,
                  },
                },
              }}
              locale={nlNL}>
              <ErrorBoundary>
                <AppRouter />
              </ErrorBoundary>
            </ConfigProvider>
          </InitWrapper>
        </AuthWrapper>
      </QueryClientProvider>
    </Suspense>
  );
};

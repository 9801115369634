import { api } from 'shared/model';

import {
  DataExchangeTableType,
  ExportDataType,
  GlazenStadType,
  ImportDataType,
} from '../pages/project/data-exchange/models/data-exchange-model';
import {
  Company,
  KisAuthorization,
  GeneralInformation,
  GlobalForm,
  HistoryType,
  ProjectType,
  SiomTemplate,
  GeometryAndConstructionType,
  SiomProjectType,
  SiomResponse,
  VerifyDataType,
  GlazenStadProjectType,
} from '../types/project.types';

export const getProjectList = (signal?: AbortSignal): Promise<ProjectType[]> =>
  api
    .get<ProjectType[]>(`/project/overview`, { signal })
    .then<ProjectType[]>(res => {
      return res.data;
    })
    .catch(e => {
      return Promise.reject(e);
    });

export const getProjectWithProjectId = (projectId: string, signal?: AbortSignal): Promise<GlobalForm> =>
  api
    .get<GlobalForm>(`/project/${projectId}`, { signal })
    .then<GlobalForm>(res => {
      return res.data;
    })
    .catch(e => {
      return Promise.reject(e);
    });

export const getSiomTemplates = (signal?: AbortSignal): Promise<SiomTemplate[]> =>
  api
    .get<SiomTemplate[]>(`/siom/templates`, { signal })
    .then<SiomTemplate[]>(res => {
      return res.data;
    })
    .catch(e => {
      return Promise.reject(e);
    });

export const calculateSiom = (projectId: string, signal?: AbortSignal): Promise<SiomResponse> =>
  api
    .post<SiomResponse>(`/siom/calculate/${projectId}`, undefined, { signal })
    .then<SiomResponse>(res => {
      return res.data;
    })
    .catch(e => {
      return Promise.reject(e);
    });

export const processTemplate = (templateId: string, projectId: string, signal?: AbortSignal): Promise<void> =>
  api
    .post(`/siom/applytemplate`, { templateId, projectId }, { signal })
    .then<void>()
    .catch(e => {
      return Promise.reject(e);
    });

export const saveProjectGeneral = (general: GeneralInformation, id: string, signal?: AbortSignal): Promise<void> =>
  api
    .post(`/general/${id}`, general, { signal })
    .then<void>()
    .catch(e => {
      return Promise.reject(e);
    });

export const saveGeometry = (geometry: GeometryAndConstructionType, id: string, signal?: AbortSignal): Promise<void> =>
  api
    .post(`/geometry/${id}`, geometry, { signal })
    .then<void>()
    .catch(e => {
      return Promise.reject(e);
    });

export const saveSiom = (siom: SiomProjectType, id: string, signal?: AbortSignal): Promise<void> =>
  api
    .post(`/siom/${id}`, siom, { signal })
    .then<void>()
    .catch(e => {
      return Promise.reject(e);
    });

export const saveGlazenStad = (glazenStad: { projectName: string; remarks: string }, id: string, signal?: AbortSignal): Promise<void> =>
  api
    .post(`/glazenStad/${id}`, glazenStad, { signal })
    .then<void>()
    .catch(e => {
      return Promise.reject(e);
    });

export const createProject = (general: GeneralInformation, signal?: AbortSignal): Promise<string> =>
  api
    .post<string>(`/createproject`, general, { signal })
    .then<string>(res => {
      return res.data;
    })
    .catch(e => {
      return Promise.reject(e);
    });

export const getDataExchangeWithProjectId = (id: string, signal?: AbortSignal): Promise<DataExchangeTableType[]> =>
  api
    .get<DataExchangeTableType[]>(`/data/${id}`, { signal })
    .then<DataExchangeTableType[]>(res => {
      return res.data;
    })
    .catch(e => {
      return Promise.reject(e);
    });

export const releaseDatasetForGlazenStad = (id: string, signal?: AbortSignal): Promise<void> =>
  api
    .get<void>(`/ReleaseDatasetForGlazenStad/${id}`, { signal })
    .then<void>()
    .catch(e => {
      return Promise.reject(e);
    });

export const getReleaseDatasetForGlazenStad = (id: string, signal?: AbortSignal): Promise<GlazenStadType> =>
  api
    .get<GlazenStadType>(`/GetReleaseDatasetForGlazenStad/${id}`, { signal })
    .then<GlazenStadType>(res => res.data)
    .catch(e => {
      return Promise.reject(e);
    });
export const downloadReleaseDatasetForGlazenStad = (id: string, signal?: AbortSignal): Promise<ExportDataType> =>
  api
    .get<ExportDataType>(`/DownloadReleaseDatasetForGlazenStad/${id}`, { signal })
    .then<ExportDataType>(res => res.data)
    .catch(e => {
      return Promise.reject(e);
    });

export const postDataExchangeFile = (data: ImportDataType, signal?: AbortSignal): Promise<void> =>
  api
    .post(`/importdataset`, data, { signal })
    .then<void>()
    .catch(e => {
      return Promise.reject(e);
    });

export const getDataExchangeExportById = (id: string, signal?: AbortSignal): Promise<ExportDataType> =>
  api
    .get<ExportDataType>(`/export/${id}`, { signal })
    .then<ExportDataType>(res => {
      return res.data;
    })
    .catch(e => {
      return Promise.reject(e);
    });

export const processFileWithId = (id: string, signal?: AbortSignal): Promise<void> =>
  api
    .get(`/process/${id}`, { signal })
    .then<void>()
    .catch(e => {
      return Promise.reject(e);
    });

export const verifyById = (id: string, signal?: AbortSignal): Promise<VerifyDataType> =>
  api
    .get(`/verify/${id}`, { signal })
    .then<VerifyDataType>(res => res.data)
    .catch(e => {
      return Promise.reject(e);
    });

export const getAuthorizations = (id: string, signal?: AbortSignal): Promise<KisAuthorization[]> =>
  api
    .get(`/authorization/${id}`, { signal })
    .then<KisAuthorization[]>(res => {
      return res.data;
    })
    .catch(e => {
      return Promise.reject(e);
    });

export const deleteAuthorization = (id: string, signal?: AbortSignal): Promise<void> =>
  api
    .delete(`/authorization/${id}`, { signal })
    .then<void>()
    .catch(e => {
      return Promise.reject(e);
    });

export const updateAuthorization = (id: string, authorization: KisAuthorization, signal?: AbortSignal): Promise<void> => {
  const model = {
    companyId: authorization.companyId,
    projectId: id,
    general: authorization.general,
    geometryAndConstruction: authorization.geometryAndConstruction,
    siom: authorization.siom,
    glazenStad: authorization.glazenStad,
  };
  return api
    .post(`/authorization/${authorization.id}`, model, { signal })
    .then<void>(res => {
      return res.data;
    })
    .catch(e => {
      return Promise.reject(e);
    });
};

export const createAuthorization = (id: string, authorization: KisAuthorization, signal?: AbortSignal): Promise<void> => {
  const model = {
    companyId: authorization.companyId,
    projectId: id,
    general: authorization.general,
    geometryAndConstruction: authorization.geometryAndConstruction,
    siom: authorization.siom,
    glazenStad: authorization.glazenStad,
  };
  return api
    .post(`/createauthorization`, model, { signal })
    .then<void>()
    .catch(e => {
      return Promise.reject(e);
    });
};

export const getHistory = (id: string, signal?: AbortSignal): Promise<HistoryType[]> =>
  api
    .get<HistoryType[]>(`/history/${id}`, { signal })
    .then<HistoryType[]>(res => {
      return res.data;
    })
    .catch(e => {
      return Promise.reject(e);
    });

export const getCompanies = (signal?: AbortSignal): Promise<Company[]> =>
  api
    .get<Company[]>(`/getcompanies`, { signal })
    .then<Company[]>(res => {
      return res.data;
    })
    .catch(e => {
      return Promise.reject(e);
    });

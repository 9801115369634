import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { Button, Col, Row, Table, TableColumnsType, notification } from 'antd';
import { Content } from 'antd/es/layout/layout';

import { faEye } from '@fortawesome/pro-duotone-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PageTitle } from '@/shared/components/page-title/pageTitle';

import { getProjectList } from '@/modules/project/model';
import { ProjectType } from '@/modules/project/types/project.types';

import { Spinal } from '@/shared/components';
import styles from './home.module.scss';

export const Home: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState<ProjectType[] | undefined>(undefined);

  useEffect(() => {
    const abortController = new AbortController();
    setIsLoading(true);
    getProjectList(abortController.signal)
      .then(data => {
        setDataSource(data);
        setIsLoading(false);
      })
      .catch(() => {
        if (abortController.signal.aborted) return;
        setDataSource([]);
        notification.error({ message: t('somethingWentWrong') });
        setIsLoading(false);
      });
    return () => {
      abortController.abort();
    };
  }, [t]);

  const handleEdit = record => {
    navigate(`/project/${record.id}`);
  };

  const columns: TableColumnsType<ProjectType> = [
    {
      title: t('projects.status'),
      dataIndex: 'status',
      key: 'status',
      render: status => {
        switch (status) {
          case 0:
            return t('projects.statuses.concept');
          case 1:
            return t('projects.statuses.offer');
          case 2:
            return t('projects.statuses.processed');
          default:
            return;
        }
      },
    },
    {
      title: t('projects.projectId'),
      dataIndex: 'projectId',
      key: 'projectId',
    },
    {
      title: t('projects.generalInformation.project.projectName.title'),
      dataIndex: 'projectName',
      key: 'projectName',
      render: projectName => <b>{projectName}</b>,
    },
    {
      title: t('projects.generalInformation.location.country.title'),
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: t('projects.generalInformation.project.contractor.title'),
      dataIndex: 'contractor',
      key: 'contractor',
    },
    {
      title: t('projects.generalInformation.project.internalId.title'),
      dataIndex: 'internalProjectId',
      key: 'internalProjectId',
    },
    {
      title: t('projects.lastAction'),
      dataIndex: 'lastAction',
      key: 'lastAction',
    },
    {
      title: '',
      dataIndex: 'role',
      key: 'role',
      render: role => <FontAwesomeIcon key={role} icon={faEye} />,
    },
  ];

  return (
    <Content>
      <Spinal initializing={false} loading={isLoading}>
        <Row gutter={[16, 48]}>
          <PageTitle title={t('projects.projects')}>
            <Link to="/new-project">
              <Button type="primary">
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: '.5rem' }} />
                {t('projects.newProject')}
              </Button>
            </Link>
          </PageTitle>

          <Col span={24}>
            <Row>
              <Table
                loading={isLoading}
                className={styles.table}
                dataSource={dataSource}
                columns={columns}
                size="small"
                pagination={false}
                onRow={record => {
                  return {
                    onClick: () => {
                      handleEdit(record);
                    },
                  };
                }}
              />
            </Row>
          </Col>
        </Row>
      </Spinal>
    </Content>
  );
};

import { faSquareKanban } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Typography } from 'antd';
import { FC } from 'react';

import styles from './pageTitle.module.scss';
import { DefaultTFuncReturn } from 'i18next';

interface PageTitleProps {
  title?: DefaultTFuncReturn | string;
  titleAppend?: string;
  children?: JSX.Element | JSX.Element[];
}

const { Title, Text } = Typography;

export const PageTitle: FC<PageTitleProps> = ({ title, titleAppend, children }) => {
  return (
    <Col span={24}>
      <Row className={styles.titleRow}>
        <Col flex={1}>
          <Title className={styles.title}>
            <FontAwesomeIcon icon={faSquareKanban} className={styles.icon} />
            {title && title}
            {titleAppend && (
              <Text className={styles.small} type="secondary">
                &nbsp;{titleAppend}
              </Text>
            )}
          </Title>
        </Col>
        <Col>{children}</Col>
      </Row>
    </Col>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Form, Row, Tooltip } from 'antd';

import { FormType } from '@/modules/project/types/project.types';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './form.module.scss';
import { renderField } from './form.utils';

interface KISFormProps {
  maxFormHeight?: string;
  formStructure: FormType;
  disableFields?: boolean;
}

export const KISForm: FC<KISFormProps> = ({ maxFormHeight = '710px', formStructure, disableFields = false }) => {
  const { t } = useTranslation();

  const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  };
  const convertHelptext = field => {
    const { step, min, max } = field;
    if (step && (+min || +max)) {
      let newStep = step.toString();
      // if step has 'e' in it
      if (newStep.indexOf('e')) newStep = newStep.replace('e', '0'.repeat(newStep.split('e-')[1] - 1));
      const minVal = min.toFixed(newStep === '1' ? 1 : newStep.length - 2).replace('.', field.dotSeparator || ',');
      const maxVal = max.toFixed(newStep === '1' ? 1 : newStep.length - 2).replace('.', field.dotSeparator || ',');

      return `${+min ? `min: ${minVal}` : ''}${max ? `${minVal && maxVal ? ' ' : ''} max: ${maxVal}` : ''}`;
    } else {
      return `${+min === 0 || +min ? `min: ${min}` : ''}${+max === 0 || +max ? `${min && max ? ' ' : ''} max: ${max}` : ''}`;
    }
  };
  const FormItemByField = ({ field }) => {
    return (
      <Row key={field.label}>
        <Col span={22} className={styles.formItemWrap}>
          <Form.Item
            className={styles.formItem}
            colon={false}
            labelCol={{ span: 10 }}
            labelAlign="left"
            label={t(field.label)}
            name={field.name}
            initialValue={field.type === 'checkbox' ? false : undefined}
            hasFeedback
            rules={[{ required: field.required, message: t('error.requiredField') || '' }]}
            valuePropName={field.type === 'checkbox' ? 'checked' : undefined}
            extra={convertHelptext(field)}>
            {renderField(field, disableFields, field.placeholder ? t(field.placeholder).toString() : '')}
          </Form.Item>
        </Col>
        <Col span={2} className={styles.tooltip}>
          {field.tooltip && t(field.tooltip).length !== 0 && (
            <Tooltip key={field.label} title={t(field.tooltip)} placement="right" overlayClassName={styles.tooltip}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <div
      className={styles.form}
      style={{
        maxHeight: maxFormHeight,
      }}>
      {formStructure.sections.map(section => (
        <div
          style={
            {
              // height: `${calculateHeight(section)}rem`,
            }
          }
          {...formItemLayout}
          className={styles.formCard}
          key={section.title}>
          {section.title && (
            <h5 className={styles.formTitle}>
              {t(section.title)} {section.icon && <FontAwesomeIcon icon={section.icon} />}
            </h5>
          )}
          {section.fields.map(field => {
            if (field.hideIfBoolFalse || field.hideIfSelectIsValue) {
              return (
                <Form.Item noStyle key={field.label || JSON.stringify(field.name)} shouldUpdate>
                  {({ getFieldValue }) => {
                    if (
                      (field.hideIfSelectIsValue !== undefined &&
                        getFieldValue(field.hideIfSelectIsValue.name) === field.hideIfSelectIsValue?.value) ||
                      (field.hideIfBoolFalse !== undefined && !getFieldValue(field.hideIfBoolFalse))
                    )
                      return null;
                    else return <FormItemByField key={field.label || JSON.stringify(field.name)} field={field} />;
                  }}
                </Form.Item>
              );
            } else if (field.hidden) return <Form.Item hidden noStyle name={field.name} />;
            else return <FormItemByField key={field.label || JSON.stringify(field.name)} field={field} />;
          })}
        </div>
      ))}
    </div>
  );
};

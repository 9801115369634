import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, Form, Row, Select, Tooltip, notification } from 'antd';

import { processTemplate } from '@/modules/project/model';
import { GlobalForm, SiomTemplate } from '@/modules/project/types/project.types';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import styles from './form.module.scss';

interface KISFormProps {
  fetchProject: () => void;
  templates?: SiomTemplate[];
  disableFields?: boolean;
}

export const TemplateForm: FC<KISFormProps> = ({ fetchProject, templates = [], disableFields = false }) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const [loading, setLoading] = useState(false);
  const form = Form.useFormInstance<GlobalForm>();

  const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  };
  const process = () => {
    if (!projectId) return;
    const template = form.getFieldValue(['siomProject', 'template', 'id']);
    setLoading(true);
    processTemplate(template, projectId)
      .then(() => {
        notification.success({ message: t('successTemplate') });
        fetchProject();
      })
      .catch(err => {
        notification.error({ message: err.request.response });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={styles.form}>
      <div {...formItemLayout} className={styles.formCard}>
        <Row>
          <Col span={22} className={styles.formItemWrap}>
            <Form.Item
              className={styles.formItem}
              colon={false}
              required
              labelCol={{ span: 10 }}
              labelAlign="left"
              label={t('projects.siom.template.title')}
              name={['siomProject', 'template', 'id']}
              hasFeedback
              rules={[{ required: false, message: t('error.requiredField') || '' }]}>
              <Select style={{ width: '100%' }} disabled={disableFields}>
                {templates.map(template => (
                  <Select.Option key={template.id} value={template.id}>
                    {template.templateName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2} className={styles.tooltip}>
            {t('projects.siom.template.tooltip').length !== 0 && (
              <Tooltip title={t('projects.siom.template.tooltip')} placement="right" overlayClassName={styles.tooltip}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            )}
          </Col>
        </Row>
        <Row></Row>
        <Row>
          <Col span={22} className={styles.formItemWrap}>
            <Button
              loading={loading}
              disabled={disableFields}
              onClick={process}
              icon={
                <FontAwesomeIcon
                  style={{
                    marginRight: '.5rem',
                  }}
                  icon={faCog}
                />
              }
              type="primary"
              style={{ float: 'inline-end' }}>
              {t('processTemplate')}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

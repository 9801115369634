// ProcessFileModal.tsx
import { processFileWithId } from '@/modules/project/model';
import { getRandomInt } from '@/shared/utilities/getRandomInt';
import { Progress } from 'antd';
import { t } from 'i18next';
import { FC, useEffect, useState } from 'react';

interface ProcessFileModalProps {
  id: string;
}

export const ProcessFileModal: FC<ProcessFileModalProps> = ({ id }) => {
  const [status, setStatus] = useState<'active' | 'exception' | undefined>('active');
  const [percent, setPercent] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const abortController = new AbortController();
    let interval = getRandomInt(200, 1000);
    setInterval(function () {
      setPercent(percent => {
        const newPercent = percent + 1;
        if (percent <= 95) return newPercent;
        else return percent;
      });
      interval = getRandomInt(200, 1000);
    }, interval);

    processFileWithId(id, abortController.signal)
      .then(() => {
        clearInterval(interval);
        setPercent(100);
      })
      .catch(err => {
        setStatus('exception');
        setPercent(100);
        setErrorMessage(err?.response?.data || err?.message);
      });
    return () => {
      abortController.abort();
    };
  }, [id]);

  return (
    <>
      {percent < 100 && t('processingPleaseWait')}
      <Progress status={status} percent={percent} />
      {errorMessage.split('- ').map(str => str && <p key={str}>{`- ${str}`}</p>)}
    </>
  );
};

export default ProcessFileModal;

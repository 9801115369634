import { Component, ErrorInfo } from 'react';

interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

export class ErrorBoundary extends Component {
  context: any;
  setState: any;
  forceUpdate: any;
  props: any;
  refs: any;

  public state: State = {
    hasError: false,
    error: undefined,
  };

  public static getDerivedStateFromError(error: Error, errorInfo: ErrorInfo): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error, errorInfo };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <>
          <h1>Sorry.. there was an error</h1>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo?.componentStack}
          </details>
        </>
      );
    }

    return this.props.children;
  }
}

import { getHistory } from '@/modules/project/model';
import { HistoryType } from '@/modules/project/types/project.types';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const History: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const [localHistoryTable, setLocalHistoryTable] = useState<HistoryType[]>([]);

  useEffect(() => {
    if (!projectId) return;
    const abortController = new AbortController();
    getHistory(projectId, abortController.signal)
      .then(res => {
        setLocalHistoryTable(res);
      })
      .catch(e => {
        if (!abortController.signal.aborted) console.error(e);
      });
    return () => {
      abortController.abort();
    };
  }, [projectId]);

  const columns: ColumnsType<HistoryType> = [
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: (date: string) => {
        return new Date(date).toLocaleString('nl');
      },
    },
    {
      title: t('activity'),
      dataIndex: 'activity',
      key: 'activity',
    },
    {
      title: t('result'),
      dataIndex: 'result',
      key: 'result',
    },
    {
      title: t('person'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('info'),
      dataIndex: 'info',
      key: 'info',
    },
  ];
  return (
    <div>
      <Table columns={columns} dataSource={localHistoryTable} pagination={false} />
    </div>
  );
};

import { Skeleton, Spin } from 'antd';
import { FC, PropsWithChildren } from 'react';

interface SpinalProps {
  initializing: boolean;
  loading: boolean;
}

export const Spinal: FC<PropsWithChildren<SpinalProps>> = ({ children, initializing, loading }) => {
  return (
    <Skeleton active loading={initializing}>
      <Spin spinning={loading}>{children}</Spin>
    </Skeleton>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from 'antd';

import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';

import { generalFormStructure } from '@/modules/project/data/formStructureGeometry.data';

import { KISForm, KISMap, ProjectCardTitle } from '../shared';

import styles from '../project.module.scss';

export const NewProject: FC = () => {
  const { t } = useTranslation();

  return (
    <Card
      className={styles.card}
      title={<ProjectCardTitle className={styles.title} icon={faCircleInfo} title={t('projects.generalInformation.title')} />}
      style={{ width: '100%' }}>
      <KISForm maxFormHeight="800px" formStructure={generalFormStructure} />
      <KISMap />
    </Card>
  );
};
